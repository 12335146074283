import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { ChromePicker } from 'react-color';
import CloseIcon from '@mui/icons-material/Close';
import {
    Grid2 as Grid,
    Divider,
    Typography,
    FormLabel,
    FormControl,
    FormControlLabel,
    Checkbox,
    IconButton
} from '@mui/material';
import { BaseTextField, CoreButton, BaseDropzone } from '../../../core';

// Helper function to determine the logo source
const getLogoSrc = (logo) => {
    if (!logo) return '';

    // Check if the logo is a base64 string
    if (typeof logo === 'string' && logo.startsWith('data:image')) {
        return logo;
    }

    // Check if the logo is a blob URL
    if (logo.preview) {
        return logo.preview;
    }

    return logo;
};

export default function CustomerPortalSettings() {
    const { control, formState: { errors }, watch, register, setValue } = useFormContext();
    return (<Grid size={12} container>
        <Grid size={12} container>
            <Grid size={6}>
                <Typography variant="h6">
                    Customer Portal Settings
                </Typography>
            </Grid>
            <Grid size={12}>
                <Divider />
            </Grid>
        </Grid>
        <Grid size={12} container>
            <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
                <Grid size={6}>
                    <Grid size={12} container>
                        <Typography id="customer-portal-link-type-change">
                            Customer Portal
                        </Typography>
                    </Grid>
                    <Grid size={12} container>
                        <FormLabel id="customer-portal-link-radio-buttons-group-label">Enable customer portal link to get orders directly from website</FormLabel>
                    </Grid>
                </Grid>
                <Grid size={6} container>
                    <Grid size={1}>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <Controller
                                rules={{ required: 'Invalid Selection' }}
                                control={control}
                                name="settings.services.customerPortal.enable"
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={watch('settings.services.customerPortal.enable')}
                                                disabled={!watch('settings.services.customerPortal.eligible')}
                                                sx={{
                                                    color: 'secondary.contrastText',
                                                    '&.Mui-checked': {
                                                        color: 'secondary.contrastText',
                                                    },
                                                }}
                                            />
                                        }
                                        sx={{
                                            '.MuiFormControlLabel-label': { ml: 1 },
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={6}>
                        {watch('settings.services.customerPortal.enable') &&
                            <BaseTextField
                                disabled
                                value={`https://${watch('settings.customerPortal.url')}`}
                                rows={3}
                                name="settings.customerPortal.url"
                                id="settings.customerPortal.url"
                                label='Url'
                                errors={errors}
                                required={false}
                            />
                        }
                    </Grid>
                    <Grid size="auto" container justifyContent='flex-end' sx={{
                        height: 55,
                    }}>
                        {watch('settings.services.customerPortal.enable') &&
                            <CoreButton
                                variant='outlined'
                                sx={{}}
                                fullWidth={false}
                                onClickHandler={() => {
                                    window.open(`https://${watch('settings.customerPortal.url')}`, '_blank');
                                }}
                            >View
                            </CoreButton>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {watch('settings.services.customerPortal.enable') && <Grid size={12} container>
            <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
                <Grid size={6}>
                    <Grid size={12} container>
                        <Typography id="customer-portal-logo-type-change">
                            Logo
                        </Typography>
                    </Grid>
                    <Grid size={12} container>
                        <FormLabel id="customer-portal-logo-link-radio-buttons-group-label">Upload logo for customer portal</FormLabel>
                    </Grid>
                </Grid>
                <Grid size={3} container>
                    <Controller
                        control={control}
                        name="settings.customerPortal.styling.logo"
                        render={({ field: { onChange } }) => {
                            return (
                                <BaseDropzone
                                    name='settings.customerPortal.styling.logo'
                                    onChange={onChange}
                                />
                            )
                        }}
                    />
                </Grid>
                <Grid size={3} container sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img
                        style={{
                            maxHeight: '150px',
                            maxWidth: '200px',
                            objectFit: 'fill',
                        }}
                        src={getLogoSrc(watch('settings.customerPortal.styling.logo'))}
                    />
                    {watch('settings.customerPortal.styling.logo') && <IconButton
                        onClick={() => {
                            setValue('settings.customerPortal.styling.logo', null, { shouldDirty: true });
                        }}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>}
                </Grid>
            </Grid>
        </Grid>}
        {watch('settings.services.customerPortal.enable') && <Grid size={12} container>
            <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
                <Grid size={6}>
                    <Grid size={12} container>
                        <Typography id="customer-portal-background-image-type-change">
                            Background Image
                        </Typography>
                    </Grid>
                    <Grid size={12} container>
                        <FormLabel id="customer-portal-background-image-link-radio-buttons-group-label">Upload background image for customer portal</FormLabel>
                    </Grid>
                </Grid>
                <Grid size={3} container>
                    <Controller
                        control={control}
                        name="settings.customerPortal.styling.backgroundImage"
                        render={({ field: { onChange } }) => {
                            return (
                                <BaseDropzone
                                    name='settings.customerPortal.styling.backgroundImage'
                                    onChange={onChange}
                                />
                            )
                        }}
                    />
                </Grid>
                <Grid size={3} container sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img
                        style={{
                            maxHeight: '150px',
                            maxWidth: '200px',
                            objectFit: 'fill',
                        }}
                        src={getLogoSrc(watch('settings.customerPortal.styling.backgroundImage'))}
                    />
                    {watch('settings.customerPortal.styling.backgroundImage') && <IconButton
                        onClick={() => {
                            setValue('settings.customerPortal.styling.backgroundImage', null, { shouldDirty: true });
                        }}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>}
                </Grid>
            </Grid>
        </Grid>}
        {watch('settings.services.customerPortal.enable') && <Grid size={12} container>
            <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
                <Grid size={6}>
                    <Grid size={12} container>
                        <Typography id="customer-portal-button-colors-type-change">
                            Button Color
                        </Typography>
                    </Grid>
                    <Grid size={12} container>
                        <FormLabel id="customer-portal-logo-link-radio-buttons-group-label">Update buttons color for customer portal</FormLabel>
                    </Grid>
                </Grid>
                <Grid size={6} container>
                    <Controller
                        control={control}
                        name="settings.customerPortal.styling.buttonColor"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <ChromePicker
                                    color={value}
                                    onChangeComplete={(color) => onChange(color.hex)}
                                />
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>}
    </Grid >
    );
}
