import * as React from 'react';
import TextField from '@mui/material/TextField';
import Prototypes from 'prop-types';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {get} from 'react-hook-form';

export default function DatePickerValue({
  id,
  label,
  name,
  InputLabelProps,
  helperText = '',
  errors,
  required = true,
  size = 'medium',
  ...rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        id={id}
        format="DD/MM/YYYY"
        label={label}
        slotProps={{
          textField: {
            fullWidth: true,
            helperText: get(errors, name)?.message || helperText,
            error: get(errors, name),
            required,
            size,
          },
        }}
        renderInput={(params) => <TextField
          {...params}
          InputLabelProps={{...InputLabelProps, shrink: true}}
          fullWidth
          InputProps={{
            ...params.InputProps,
          }}
        />}
        {...rest}
      />
    </LocalizationProvider>
  );
}

DatePickerValue.prototypes = {
  id: Prototypes.string.isRequired,
  label: Prototypes.string.isRequired,
  name: Prototypes.string.isRequired,
  required: Prototypes.bool.isRequired,
  options: Prototypes.array.isRequired,
};
