import { createApi } from '@reduxjs/toolkit/query/react';
import { generateRequestOptions, baseQueryWithReAuth } from '../../actions/action_helper';

const dataImportApi = createApi({
    reducerPath: 'DataImportApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getUploadUrl: builder.query({
            query: () => ({
                ...generateRequestOptions({
                    url: '/app/data_import/url',
                    method: 'GET',
                }),
            }),
        }),
        startProcessing: builder.mutation({
            query: (data) => ({
                ...generateRequestOptions({
                    url: '/app/data_import/start',
                    method: 'POST',
                    data
                }),
            }),
        }),
    }),
});

export const { useGetUploadUrlQuery, useStartProcessingMutation } = dataImportApi;
export default dataImportApi;
