import {createApi} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat} from '../../actions/action_helper';

const StopsApi = createApi({
  reducerPath: 'Stops Api',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    createStop: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: `/app/stops`,
          method: 'POST',
          data,
        }),
      }),
    }),
    getStops: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/stops?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getStop: builder.query({
      query: (id) => ({
        ...generateRequestOptions({
          url: `/app/stops/${id}`,
          method: 'GET',
        }),
      }),
    }),
    updateStop: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: `/app/stops/${data?.id}`,
          method: 'PUT',
          data,
        }),
      }),
    }),
  }),
});

export const {
  useCreateStopMutation,
  useGetStopsQuery,
  useLazyGetStopsQuery,
  useGetStopQuery,
  useLazyGetStopQuery,
  useUpdateStopMutation,
} = StopsApi;

export default StopsApi;
