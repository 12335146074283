import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Grid, IconButton, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { CoreButton, DatePicker, Hooks } from '../../../core';
import { DELIVERY_METHOD, TIFFIN, STORAGE_KEYS } from '../../../js/lib/constants';
import { getTimezone } from '../../../js/lib/utils';

const {useSessionStoragePersist} = Hooks;
export default function RouteFilterModal({ open, handleClose, filterObject, setFilterCount, setPage, setFilterObject }) {
  const [statusFilter, setStatusFilter] = useState(filterObject?.status ? new Set(filterObject?.status.split(',')) : new Set());
  const [driverAssignedFilter, setDriverAssignedFilter] = useState(new Set());
  const settings = useSelector((store) => store?.settings?.settings);
  const timezone = getTimezone(settings?.timezone);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    setFilterCount(
      statusFilter.size +
      driverAssignedFilter.size
    );
  }, [statusFilter, driverAssignedFilter]);

  useSessionStoragePersist({ value: filterObject, sessionStorageKey: STORAGE_KEYS.ROUTE_FILTER });

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Grid
        container
        spacing={1}
        sx={style}>
        <Grid item xs={12} container>
          <Typography variant='h6' fontWeight="bold">Filters</Typography>
          <Grid>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4} container alignItems="center">
            <Typography variant='h7'>Status</Typography>
          </Grid>
          <Grid item xs={8} container>
            {Object.values(TIFFIN.DELIVERY_ROUTES_STATUS).map((item) => <FormControlLabel key={item} control={<Checkbox
              sx={{
                '&, &.Mui-checked': {
                  color: 'secondary.contrastText',
                },
              }}
              checked={statusFilter.has(item)}
              onChange={(e) => {
                if (e.target.checked) {
                  statusFilter.add(item);
                  setStatusFilter(new Set(statusFilter));
                } else {
                  statusFilter.delete(item);
                  setStatusFilter(new Set(statusFilter));
                }
              }}
            />} label={item} />)}
          </Grid>
        </Grid>
        <Grid item xs={12} container sx={{ mt: 4 }}>
          <Grid item xs={4} container>
            <CoreButton
              disableRipple
              fullWidth={false}
              sx={{
                color: 'secondary.contrastText',
                textDecoration: 'underline',
              }}
              onClickHandler={() => {
                setStatusFilter(new Set());
                setDriverAssignedFilter(new Set());
                sessionStorage.removeItem(STORAGE_KEYS.ROUTE_FILTER);
              }}
            >
              Clear All Filters
            </CoreButton>
          </Grid>
          <Grid item xs={8} container justifyContent="flex-end" spacing={1}>
            <Grid item xs={6}>
              <CoreButton
                variant='outlined'
                onClickHandler={() => {
                  handleClose();
                }}
                sx={{}}
              >
                Cancel
              </CoreButton>
            </Grid>
            <Grid item xs={6}>
              <CoreButton
                variant='contained'
                onClickHandler={() => {
                  const filterObject = {
                    status: Array.from(statusFilter)?.join(','),
                  };
                  setFilterObject(filterObject);
                  setPage(1);
                  handleClose();
                }}
              >
                Apply Filters
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
