import React, { useEffect, useState } from 'react';
import { Grid, Typography, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';

import { TuiAppBar, Hooks, BasePagination, DataNotFound, TuiSpinner } from '../../../core';
import { deliveryRoutesApi } from '../../../js/slices/api_slices';
import ListComponent from '../components/routes_list_component';
import RouteFilterModal from '../components/route_filter_modal';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const { useGetDeliveryRoutesQuery } = deliveryRoutesApi;
const { useResponsivePageSize, usePageNumberAndSearch, useDebounce } = Hooks;

export default function RoutesView() {
  const [pageSize, setPageSize] = useResponsivePageSize();
  const navigate = useNavigate();
  const [filterCount, setFilterCount] = useState(0);
  const [items, setItems] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  let storageFilter = sessionStorage.getItem(STORAGE_KEYS.ROUTE_FILTER);
  if (storageFilter) {
    storageFilter = JSON.parse(storageFilter);
  } else {
    sessionStorage.setItem(STORAGE_KEYS.ROUTE_FILTER, JSON.stringify({}));
  }
  const [filterObject, setFilterObject] = useState({
    status: storageFilter?.status || '',
  });

  const [page, setPage, searchText, setSearchText] = usePageNumberAndSearch();

  const debouncedSearchTerm = useDebounce(searchText, 500);

  const { data, isLoading, isSuccess, refetch } = useGetDeliveryRoutesQuery({
    page,
    pageSize,
    contains: { 'name': debouncedSearchTerm },
    sortKey: '+name',
    status: filterObject?.status,
  }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (data) {
      setItems(data?.data?.items);
    }
  }, [data, isSuccess, refetch]);

  const filterModal =
    <RouteFilterModal
      open={showFilterModal}
      filterObject={filterObject}
      handleClose={() => setShowFilterModal(false)}
      setFilterCount={setFilterCount}
      setFilterObject={setFilterObject}
      setPage={setPage}
    />;

  if (isLoading) {
    return <TuiSpinner />
  }

  return (
    <>
      <Grid container sx={{ height: data?.data?.items?.length <= 0 ? '100%' : null }}>
        <Grid item xs={12} md={12} lg={12}>
          <TuiAppBar
            buttonClickHandler={() => {
              navigate('new', { state: location.state });
            }}
            setSearchText={setSearchText}
            searchText={searchText}
            searchHelperText='Name'
            title={<Box display="flex" alignItems="center">
              <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
                Routes
              </Typography>
              <IconButton
                onClick={() => refetch()}
                aria-label="refresh"
                size="medium">
                <RefreshIcon fontSize="inherit" style={{ color: '#FF4444' }} />
              </IconButton>
            </Box>}
            filter={{ filterModal, showFilterModal, setShowFilterModal, filterCount }}
          />
        </Grid>
        <Grid item xs={12}>
          {!items?.length && <DataNotFound title="routes" />}
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }}>
          {items?.length > 0 && <ListComponent
            isLoading={isLoading}
            items={items}
            refetch={refetch}
            setItems={setItems}
            setPage={setPage}
            page={page}
          />}
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end' alignItems="flex-end" sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <BasePagination
          data={data}
          pageSize={pageSize}
          page={page - 1}
          pageSizeChangeHandler={(event) => {
            setPageSize(parseInt(event.target.value));
            setPage(1);
          }}
          pageChangeHandler={(event, val) => {
            setPage(val + 1);
          }}
        />
      </Grid>
    </>
  );
}
