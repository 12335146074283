import { createApi } from '@reduxjs/toolkit/query/react';
import { generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat } from '../../actions/action_helper';

const leadsApi = createApi({
    reducerPath: 'Leads Api',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getLeads: builder.query({
            query: (args) => ({
                ...generateRequestOptions({
                    url: `/app/leads?${objectToQueryFormat(args)}`,
                    method: 'GET',
                }),
            }),
        }),
        getLead: builder.query({
            query: (args) => ({
                ...generateRequestOptions({
                    url: `/app/leads/${args.id}`,
                    method: 'GET',
                }),
            }),
        }),
        updateLead: builder.mutation({
            query: (data) => ({
                ...generateRequestOptions({
                    url: `/app/leads/${data?.id}`,
                    method: 'PUT',
                    data,
                }),
            }),
        }),
        deleteLead: builder.mutation({
            query: (data) => ({
                ...generateRequestOptions({
                    url: `/app/leads/${data?.id}`,
                    method: 'DELETE',
                    data,
                }),
            }),
        }),
    }),
});

export const {
    useGetLeadQuery,
    useGetLeadsQuery,
    useLazyGetLeadQuery,
    useLazyGetLeadsQuery,
    useUpdateLeadMutation,
    useDeleteLeadMutation,
} = leadsApi;

export default leadsApi;
