import {useSearchParams} from 'react-router-dom';

export default function useQueryParams() {
  const [queryParams, seQueryParams] = useSearchParams();

  const getParam = (key, defaultVal) => {
    if (queryParams.get(key)) {
      return queryParams.get(key);
    }

    return defaultVal;
  };

  const setParam = (key, value) => {
    queryParams.set(key, value.toString());
    seQueryParams(queryParams);
  };


  return {
    getParam,
    setParam,
  };
}
