import React, { useEffect, useState } from 'react';
import { Grid2 as Grid, Typography, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';

import { TuiAppBar, Hooks, BasePagination, DataNotFound, TuiSpinner, CoreButton } from '../../../core';
import { leadsApi } from '../../../js/slices/api_slices';
import ListComponent from '../components/lead_list_component';
import LeadFilterModal from '../components/lead_filter_modal';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const { useGetLeadsQuery, useLazyGetLeadsQuery } = leadsApi;
const { useResponsivePageSize, usePageNumberAndSearch, useDebounce } = Hooks;

export default function ExpensesView() {
  const [pageSize, setPageSize] = useResponsivePageSize();
  const navigate = useNavigate();
  const [filterCount, setFilterCount] = useState(0);
  const [items, setItems] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  let storageFilter = sessionStorage.getItem(STORAGE_KEYS.LEADS_FILTER);
  if (storageFilter) {
    storageFilter = JSON.parse(storageFilter);
  } else {
    sessionStorage.setItem(STORAGE_KEYS.LEADS_FILTER, JSON.stringify({}));
  }
  const [filterObject, setFilterObject] = useState({
    startDate: storageFilter?.startDate || '',
    endDate: storageFilter?.endDate || '',
  });

  const [page, setPage, searchText, setSearchText] = usePageNumberAndSearch();

  const debouncedSearchTerm = useDebounce(searchText, 500);

  const [trigger] = useLazyGetLeadsQuery({});

  const leadsFetchQuery = {
    page,
    pageSize,
    contains: { 'name': debouncedSearchTerm },
    sortKey: '-startDate',
    startTimeFrom: filterObject?.startDate,
    startTimeEnd: filterObject?.endDate,
  };

  const { data, isLoading, isSuccess, refetch } = useGetLeadsQuery(leadsFetchQuery, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (data) {
      setItems(data?.data?.items);
    }
  }, [data, isSuccess, refetch]);

  const fetchAllPages = async (page = 1, pageSize = 200) => {
    const response = await trigger(leadsFetchQuery);

    let data = response?.data?.data;
    const totalCount = data.totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);

    if (page < totalPages) {
      const nextPageData = await fetchAllPages(page + 1, pageSize);
      data = { ...data, items: [...data.items, ...nextPageData.items] };
    }

    return data;
  };

  const filterModal =
    <LeadFilterModal
      open={showFilterModal}
      filterObject={filterObject}
      handleClose={() => setShowFilterModal(false)}
      setFilterCount={setFilterCount}
      setFilterObject={setFilterObject}
      setPage={setPage}
    />;

  if (isLoading) {
    return <TuiSpinner />
  }

  return (
    <>
      <Grid container sx={{ height: data?.data?.items?.length <= 0 ? '100%' : null }}>
        <Grid size={12}>
          <TuiAppBar
            setSearchText={setSearchText}
            searchText={searchText}
            searchHelperText='Name'
            title={<Box display="flex" alignItems="center">
              <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
                Leads
              </Typography>
              <IconButton
                onClick={() => refetch()}
                aria-label="refresh"
                size="medium">
                <RefreshIcon fontSize="inherit" style={{ color: '#FF4444' }} />
              </IconButton>
            </Box>}
            filter={{ filterModal, showFilterModal, setShowFilterModal, filterCount }}
          />
        </Grid>
        <Grid size={12}>
          {!items?.length && <DataNotFound title="leads" />}
        </Grid>
        <Grid size={12} sx={{ pt: 2 }}>
          {items?.length > 0 && <ListComponent
            isLoading={isLoading}
            items={items}
            refetch={refetch}
            setItems={setItems}
            setPage={setPage}
            page={page}
          />}
        </Grid>
      </Grid >
      <Grid container size={12} justifyContent='flex-end' alignItems="flex-end" sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <BasePagination
          data={data}
          pageSize={pageSize}
          page={page - 1}
          pageSizeChangeHandler={(event) => {
            setPageSize(parseInt(event.target.value));
            setPage(1);
          }}
          pageChangeHandler={(event, val) => {
            setPage(val + 1);
          }}
        />
      </Grid>
    </>
  );
}
