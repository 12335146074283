import React from 'react';
import { Grid2 as Grid } from '@mui/material';

const Footer = ({ children }) => {
    return (
        <Grid
            size={12}
            component="footer"
            sx={{
                backgroundColor: 'white',
                boxShadow: 3,
                borderRadius: 3,
                position: 'fixed',
                bottom: 0,
                left: 0,
                zIndex: 1000,
            }}
        >
            <Grid container size={12} sx={{p: 1}}>
                {children}
            </Grid>
        </Grid>
    );
};

export default Footer;