import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';
import EmployeesView from './views/employees_view';
import CreateEmployeeView from './views/create_employee_view';
import EditEmployeeView from './views/edit_employee_view';

export default function Orders() {
  return (
    <Routes>
      <Route path="/" element={<EmployeesView />} />
      <Route path="new" element={<CreateEmployeeView />} />
      <Route path=":id" element={<EditEmployeeView />} />
      <Route path='*' element={<ContentNotFound />} />
    </Routes>
  );
}
