import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {useLogoutMutation} from '../../../js/slices/api_slices/auth_api_slice';
import {ConfirmationModal, TuiSpinner} from '../../../core';
import {logout} from '../../../js/slices/reducer_slices/user_reducer_slice';
import useSnackBarNotification from '../../../core/hooks/use_snack_bar_notification';

export default function LogoutComponent({displayModal, setDisplayModal}) {
  const {errorSnackBar} = useSnackBarNotification();
  const dispatch = useDispatch();

  const [logoutAction, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useLogoutMutation();

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(logout());
      setDisplayModal(false);
    }
    if (error && isError) {
      errorSnackBar({message: error?.data?.errorMessage});
      setDisplayModal(false);
    }
  }, [isSuccess, data, error, isError, isLoading]);

  if (isLoading) {
    return <TuiSpinner />;
  }
  
  return (
    <ConfirmationModal
      open={displayModal}
      handleClose={() => setDisplayModal(false)}
      title="Logout"
      actionText="Are you sure you want to logout?"
      actionHandler={logoutAction}
      type="Logout"
    />
  );
}
