import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid2 as Grid, IconButton, Menu, MenuItem, ListItemIcon, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { leadsApi } from '../../../js/slices/api_slices';
import { Hooks, Table, ConfirmationModal } from '../../../core/index';
import { getFormattedPhoneNumber, getFormattedDateTimeFromUnixTimestamp, getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';
import { LEADS } from '../../../js/lib/constants';
import { getStatusChip } from '../lead_utils';

const headCells = [{
  id: 'name',
  title: 'Name',
},{
  id: 'address',
  title: 'Address',
}, {
  id: 'phoneNumber',
  title: 'Phone Number',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'startDate',
  title: 'Start Date',
}, {
  id: 'mealPlan',
  title: 'Meal Plan',
}, {
  id: 'createdAt',
  title: 'Created At',
}, {
  id: 'action',
  title: 'Actions',
}];

const { useUpdateLeadMutation, useDeleteLeadMutation } = leadsApi;

export default function LeadListComponent({ items, isLoading, refetch, setItems, page, setPage }) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] = useState(false);
  const [showCompleteConfirmationModal, setShowCompleteConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const [updateLead, {
    data: updateLeadData,
    isSuccess: updateLeadIsSuccess,
    error: updateLeadError,
    isError: updateLeadIsError,
    isLoading: updateLeadIsLoading
  }] = useUpdateLeadMutation();

  const [deleteLead, {
    data: deleteLeadData,
    isSuccess: deleteLeadIsSuccess,
    error: deleteLeadError,
    isError: deleteLeadIsError,
    isLoading: deleteLeadIsLoading
  }] = useDeleteLeadMutation();

  useEffect(() => {
    if (updateLeadIsSuccess && updateLeadData) {
      successSnackBar({ message: 'Lead updated successfully' });
      setItems(items.map((item) => item?.id === updateLeadData?.data?.id ? updateLeadData?.data : item));
    } if (updateLeadIsError && updateLeadError) {
      errorSnackBar({ message: updateLeadError?.data?.errorMessage });
    }
    setSelectedItem(null);
  }, [updateLeadIsSuccess, updateLeadData, updateLeadError, updateLeadIsError]);

  useEffect(() => {
    if (deleteLeadIsSuccess && deleteLeadData) {
      successSnackBar({ message: 'Lead deleted successfully' });
      const newRows = items.filter((row) => row?.id !== deleteLeadData?.data?.id);
      if (!newRows.length && Number(page) > 1) {
        setPage(page - 1);
      }
      setItems(newRows);
    } if (deleteLeadIsError && deleteLeadError) {
      errorSnackBar({ message: deleteLeadError?.data?.errorMessage });
    }
  }, [deleteLeadIsSuccess, deleteLeadData, deleteLeadIsError, deleteLeadError]);

  const menuOptions = (item) => [{
    id: 'accepted',
    title: 'Accept',
    disabled: item?.status === LEADS.STATUS.ACCEPTED,
    icon: <CheckCircleOutlineIcon fontSize="small" />,
    action: () => {
      setShowCompleteConfirmationModal(true);
    }
  }, {
    id: 'reject',
    title: 'Reject',
    icon: <CancelIcon fontSize="small" />,
    disabled: item?.status === LEADS.STATUS.REJECTED,
    action: () => {
      setShowRejectConfirmationModal(true);
    }
  }, {
    id: 'delete',
    title: 'Delete',
    icon: <DeleteIcon fontSize="small" />,
    action: () => {
      setShowDeleteConfirmationModal(true);
    }
  }];

  const processedItems = items?.map((item) => {
    const { firstName, lastName, mealPlan, startDate, id, phoneNumber, createdAt, address } = item;
    return {
      name: `${firstName} ${lastName}`,
      address,
      phoneNumber: getFormattedPhoneNumber(phoneNumber?.number),
      status: getStatusChip(item?.status),
      startDate: getFormattedDateFromUnixTimestamp(startDate),
      mealPlan: mealPlan?.name,
      createdAt: getFormattedDateTimeFromUnixTimestamp(createdAt),
      action: <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => {
            setSelectedItem(item);
            handleClick(event);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        {selectedItem?.id === item?.id && <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuOptions(item).map(({ id, title, icon, action, disabled }) => [
            <MenuItem
              disabled={disabled}
              key={id}
              onClick={(e) => {
                action();
                handleClose(e);
              }}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              {title}
            </MenuItem>,
          ])}
        </Menu>}
      </div>,
      id,
    };
  });

  const rejectConfirmationModal = <ConfirmationModal
    open={showRejectConfirmationModal}
    handleClose={() => setShowRejectConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant='h6'>Are you sure want to reject this lead?</Typography>
      </Grid>
    </Grid>}
    type="Reject"
    actionHandler={() => {
      setShowRejectConfirmationModal(false);
      updateLead({ id: selectedItem?.id, status: LEADS.STATUS.REJECTED });
    }}
  />;

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteConfirmationModal}
    handleClose={() => setShowDeleteConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant='h6'>Are you sure want to delete this lead?</Typography>
      </Grid>
    </Grid>}
    type="Delete"
    actionHandler={() => {
      setShowDeleteConfirmationModal(false);
      deleteLead({ id: selectedItem?.id });
    }}
  />;

  const changeToCustomerConfirmationModal = <ConfirmationModal
    open={showCompleteConfirmationModal}
    handleClose={() => setShowCompleteConfirmationModal(false)}
    title={<Typography variant="h6">
      Are you sure want to accept this to customer?
    </Typography>}
    type="Accept"
    actionHandler={() => {
      setShowCompleteConfirmationModal(false);
      updateLead({ id: selectedItem?.id, status: LEADS.STATUS.ACCEPTED });
    }}
  />;

  return (
    <>
      <Grid container>
        <Table
          headers={headCells} data={processedItems} isLoading={isLoading} setSelectedRow={(row) => {
            navigate(row.id);
          }} />
      </Grid>
      {rejectConfirmationModal}
      {changeToCustomerConfirmationModal}
      {deleteConfirmationModal}
    </>
  );
}
