import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import RouteStopForm from '../components/route_stop_form';
import { stopsApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, CoreButton } from '../../../core/index';

const { useSnackBarNotification } = Hooks;
const { useCreateStopMutation } = stopsApi;

export default function CreateRouteStop() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, handleSubmit, formState: { errors }, control, getValues, watch } = useForm({
    defaultValues: {},
    mode: 'onChange',
  });
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();

  const [addNewStop, {
    data: employeeData,
    isSuccess: employeeIsSuccess,
    error: employeeError,
    isError: employeeIsError,
    isLoading: employeeIsLoading,
  }] = useCreateStopMutation();

  useEffect(() => {
    if (employeeIsSuccess && employeeData) {
      successSnackBar({ message: 'Stop created successfully' });
      navigate(-1);
    } if (employeeIsError && employeeError) {
      errorSnackBar({ message: employeeError?.data?.errorMessage });
    }
  }, [employeeIsSuccess, employeeIsError, employeeData, employeeError]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TuiAppBar title='New Stop' />
        </Grid>
        <Grid
          component='form'
          onSubmit={handleSubmit(async (userInput) => {
            userInput.routeId = id;
            addNewStop(userInput);
          })}
          sx={{ mt: 1 }}
          container
        >
          <Grid item xs={12} container sx={{ p: 1, height: 'inherit' }}>
            <RouteStopForm
              getValues={getValues}
              watch={watch}
              control={control}
              register={register}
              errors={errors}
              viewOnly={false}
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            justifyContent='flex-end'
            alignContent='flex-end'
            sx={{ bottom: 40, position: 'fixed', right: 20, width: '100%' }}
          >
            <Grid item>
              <CoreButton
                isLoading={employeeIsLoading}
                fullWidth={true}
                onClickHandler={() => navigate(-1)}
                variant="contained"
              >
                Back
              </CoreButton>
            </Grid>
            <Grid item>
              <CoreButton
                isLoading={employeeIsLoading}
                type='submit'
                fullWidth={true}
                variant="contained"
              >
                Add
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
