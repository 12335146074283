import {createSlice} from '@reduxjs/toolkit';
import authApi from '../api_slices/auth_api_slice';

const initialState = {
  token: null,
  refreshToken: null,
  notificationToken: null,
  userDetails: null,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    logout(state) {
      state.userDetails = null;
      state.token = null;
      localStorage.removeItem('token');
    },
    setUserDetails(state, {payload}) {
      state.token = payload.data.token;
      localStorage.setItem('token', payload.data.token);
      delete payload?.data.token;
      state.userDetails = payload.data;
    },
    setNotificationToken(state, {payload}) {
      state.notificationToken = payload.data.token;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.logout.matchFulfilled,
      () => {
        localStorage.removeItem('token');
      },
    );
    builder.addMatcher(
      authApi.endpoints.refreshToken.matchFulfilled,
      (state, {payload}) => {
        const {data} = payload;
        state.token = data.token;
        localStorage.setItem('token', data.token);
        delete data.token;
        state.userDetails = data;
      },
    );
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, {payload}) => {
        const {data} = payload;
        state.token = data.token;
        localStorage.setItem('token', data.token);
        delete data.token;
        state.userDetails = data;
      },
    );
  },
});

const {actions, reducer} = userSlice;

export const {logout, setUserDetails} = actions;

export default reducer;
