import {createSlice} from '@reduxjs/toolkit';
import rolesApi from '../api_slices/roles_api_slice';

const initialState = {
  roles: {},
};

const rolesSlice = createSlice({
  name: 'rolesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      rolesApi.endpoints.getRoles.matchFulfilled,
      (state, {payload}) => {
        const {data} = payload;
        state.roles = data.roles;
      },
    );
  },
});

const {reducer} = rolesSlice;

export default reducer;
