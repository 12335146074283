import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid2 as Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import RouteForm from '../components/route_form';
import { deliveryRoutesApi } from '../../../js/slices/api_slices';
import { TIFFIN } from '../../../js/lib/constants';

import { TuiAppBar, Hooks, TuiSpinner, Footer, CoreButton } from '../../../core/index';

const { useGetDeliveryRouteQuery, useUpdateDeliveryRouteMutation } = deliveryRoutesApi;

export default function EditRouteView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();

  const { data: deliveryRouteData, isLoading: deliveryRouteLoading } = useGetDeliveryRouteQuery({
    id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const startTime = deliveryRouteData?.data?.startTime;
  const startTimeMoment = startTime ? moment(startTime) : moment().set({ h: 17, m: 0, s: 0 });

  const methods = useForm({
    defaultValues: {
      ...deliveryRouteData?.data,
      startTime: startTimeMoment,
      startAddress: null,
      finishAddress: null,
      driver: null,
      type: null,
      deliveryArea: {
        ...deliveryRouteData?.data?.deliveryArea,
        type: null,
        coordinates: [],
      }
    },
  });
  const { reset, handleSubmit, watch, formState: { isDirty } } = methods;
  const viewOnly = watch('status') === TIFFIN.DELIVERY_ROUTES_STATUS.CANCELLED;

  useEffect(() => {
    if (deliveryRouteData) {
      reset({
        ...deliveryRouteData.data,
        startTime: moment(deliveryRouteData?.data?.startTime) || moment().set({ h: 17, m: 0, s: 0 }), // Adjust this as necessary,
        deliveryArea: {
          ...deliveryRouteData?.data?.deliveryArea,
          type: deliveryRouteData?.data?.deliveryArea?.type || null,
          coordinates: deliveryRouteData?.data?.deliveryArea?.coordinates?.[0] || [],
        },
      });
    }
  }, [deliveryRouteData, reset]);

  const [updateRoute, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useUpdateDeliveryRouteMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Route modified successfully' });
      reset({
        finishAddress: null
      });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
  }, [isSuccess, isError, data, error]);


  if (deliveryRouteLoading) return <TuiSpinner />;

  return (
    <Grid
      container
      component='form'
      onSubmit={handleSubmit((userInput) => {
        userInput = {
          ...userInput,
          startTime: moment(userInput.startTime).valueOf(),
        };
        updateRoute(userInput);
      })}>
      <Grid container spacing={1} size={12}>
        <Grid size={12}>
          <TuiAppBar title="Edit Route" />
        </Grid>
        <Grid
          container
          sx={{ mt: 1 }}
          size={12}>
          {!deliveryRouteLoading && deliveryRouteData?.data && <FormProvider {...methods}>
            <Grid size={12} container sx={{ p: 1 }}>
              <RouteForm
                viewOnly={viewOnly}
                item={deliveryRouteData?.data}
              />
            </Grid>
          </FormProvider>}
          <Footer>
            <Grid
              container
              justifyContent='flex-end'
              size={12}
              spacing={2}
              sx={{ p: 1 }}
            >
              <Grid>
                <CoreButton
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                  fullWidth={false}
                  variant='outlined'
                  onClick={() => navigate(-1)}
                >
                  Back
                </CoreButton>
              </Grid>
              <Grid>
                <CoreButton
                  startIcon={<SaveOutlinedIcon />}
                  type='submit'
                  fullWidth={false}
                  variant='contained'
                  isLoading={isLoading}
                  disabled={!isDirty}
                >
                  Save
                </CoreButton>
              </Grid>
            </Grid>
          </Footer>
        </Grid>
      </Grid>
    </Grid>
  );
}
