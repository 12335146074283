import React, { useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid2 as Grid, Typography } from '@mui/material';

const baseStyle = {
  padding: '20px',
  alignItems: 'center',
  borderWidth: 2,
  borderColor: ' #bdbdbd',
  borderStyle: 'dashed',
  borderRadius: '15px',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function BaseDropzone({ onChange }) {
  const [files, setFiles] = useState([]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(acceptedFiles);
      onChange(acceptedFiles[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <Grid
      size={12}
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{
        ...style,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Grid size="auto">
        <Typography
          sx={{
            color: 'secondary.contrastText',
            textDecoration: 'underline',
            ':hover': {
              cursor: 'pointer',
            },
          }}
        >
          Choose file
        </Typography>
      </Grid>
      <Grid size="auto">
        <Typography>or Drag file here</Typography>
      </Grid>
      {files?.map((file) => {
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          return (
            <Grid key={file.name} size="auto" onClick={() => {}}>
              <Typography
                sx={{
                  textDecoration: 'underline',
                }}
              >
                {file.name}
              </Typography>
            </Grid>
          );
        }
      })}
    </Grid>
  );
}