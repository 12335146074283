import {createApi} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions, baseQueryWithReAuth} from '../../actions/action_helper';

const rolesApi = createApi({
  reducerPath: 'RolesApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => ({
        ...generateRequestOptions({
          url: '/app/roles',
          method: 'GET',
        }),
      }),
    }),
  }),
});

export const {useGetRolesQuery} = rolesApi;
export default rolesApi;
