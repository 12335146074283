import React from 'react';
import { TablePagination } from '@mui/material';
import { useLocalStoragePersist } from './hooks';

export default function BasePagination({ data, pageSize, page, pageChangeHandler, pageSizeChangeHandler, localStorageKey }) {

  if (localStorageKey) {
    useLocalStoragePersist({ value: { pageSize, page }, localStorageKey: localStorageKey });
  }

  return <TablePagination
    count={data?.data?.totalCount}
    page={page}
    rowsPerPage={pageSize}
    onRowsPerPageChange={pageSizeChangeHandler}
    onPageChange={pageChangeHandler}>
  </TablePagination>;
}
