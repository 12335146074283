import React from 'react';
import { Grid } from '@mui/material';
import { useLottie } from 'lottie-react';
import Loader from '../assets/loader/loader.json';


export default function TuiSpinner() {
  const options = {
    animationData: Loader,
    loop: true,
  };
  const { View } = useLottie(options);
  return (
    <Grid container justifyContent='center'>
      {View}
    </Grid>
  );
}