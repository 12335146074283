import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';
import RoutesView from './views/routes_view';
import CreateRouteView from './views/create_route_view';
import EditRouteView from './views/edit_route_view';
import RouteStopsView from './views/route_stops_view';
import RouteStopsForm from './views/create_route_stop_view';
import EditStopView from './views/edit_route_stop_view';

export default function Orders() {
  return (
    <Routes>
      <Route path="/" element={<RoutesView />} />
      <Route path="new" element={<CreateRouteView />} />
      <Route path=":id" element={<EditRouteView />} />
      <Route path=":id/stops" element={<RouteStopsView />} />
      <Route path=":id/stops/new" element={<RouteStopsForm />} />
      <Route path=":id/stops/:stopId" element={<EditStopView />} />
      <Route path='*' element={<ContentNotFound />} />
    </Routes>
  );
}
