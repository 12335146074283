import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';
import CustomerView from './views/customers_view';
import CreateCustomerView from './views/create_customer_view';
import EditCustomerView from './views/edit_customer_view';

export default function Orders() {
  return (
    <Routes>
      <Route path="/" element={<CustomerView />} />
      <Route path="new" element={<CreateCustomerView />} />
      <Route path=":id" element={<EditCustomerView />} />
      <Route path='*' element={<ContentNotFound />} />
    </Routes>
  );
}
