import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import {
  IconButton,
  Divider,
  Drawer,
  Box,
  List,
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton
} from '@mui/material';

import MenuIcon from '@mui/icons-material/MenuOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined';
import ExpandLess from '@mui/icons-material/ExpandLessOutlined';
import ExpandMore from '@mui/icons-material/ExpandMoreOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';

import LogoutComponent from '../../login/components/logout_component';
import Logo from '../../../assets/images/dinespot_black_logo.png';
import { drawerNavLocations } from './drawer_locations';
import { SIDE_DRAWER_WIDTH } from '../../../js/lib/constants';

const openedMixin = (theme) => ({
  width: SIDE_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: SIDE_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function HomePageDrawer() {
  const location = useLocation();
  const [showLogout, setShowLogout] = useState(false);
  const [openedNestedRoute, setOpenNestedRoute] = useState('');
  const [open, setOpen] = useState(true);
  const [selectedPath, setSelectedPath] = useState(location?.pathname);
  const roles = useSelector((store) => store?.roles.roles);
  const navigate = useNavigate();

  const handleDrawer = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    setSelectedPath(location?.pathname);
  },
    [location]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AppDrawer variant="permanent" open={open} PaperProps={{
          sx: {
            boxShadow: 3,
            borderRadius: 3
          },
        }} >
          <DrawerHeader>
            {open && <>
              <img alt='logo' src={Logo} width='130' onClick={handleDrawer} />
              <IconButton onClick={handleDrawer} sx={{ color: 'white' }}>
                <ChevronLeftIcon sx={{
                  color: 'black',
                }} />
              </IconButton></>}
            {!open && <IconButton onClick={handleDrawer} sx={{ color: 'white', pr: 2.25 }}>
              <MenuIcon
                sx={{ color: 'black' }}
              />
            </IconButton>}
          </DrawerHeader>
          <Divider sx={{ borderColor: 'grey' }} />
          <List>
            {drawerNavLocations.map(({ path, name, icon, rolesAvailability, routes }) => (
              <ListItem
                key={name}
                disablePadding
                sx={{ display: 'block', p: 0.7 }} // Adds margin-bottom for spacing
              >
                {roles && (roles[rolesAvailability]?.read || rolesAvailability === 'settings') && (
                  <>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        backgroundColor: !routes && selectedPath.includes(path) ? '#FF4444' : 'transparent',
                        boxShadow: !routes && selectedPath.includes(path) ? 3 : 'none', // Adds shadow when selected
                        borderRadius: '10px', // Adds rounded corners when selected
                        '&:hover': {
                          backgroundColor: '#FF4444',
                          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                            color: 'white',
                          },
                          boxShadow: 3, // Adds shadow
                          borderRadius: '10px', // Adds rounded corners
                        },
                      }}
                      onClick={() => {
                        if (routes) {
                          if (openedNestedRoute === path) {
                            setOpenNestedRoute('');
                          } else {
                            setOpenNestedRoute(path);
                          }
                        } else {
                          setOpenNestedRoute('');
                          navigate(path);
                        }
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1 : 'auto',
                          color: !routes && selectedPath.includes(path) ? 'white' : 'black',
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={name}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: !routes && selectedPath.includes(path) ? 'white' : 'black',
                        }}
                      />
                      {routes && <>{openedNestedRoute === path ? <ExpandLess
                        sx={{
                          color: 'black',
                          '&:hover': {
                            color: 'white',
                          },
                        }}

                      /> : <ExpandMore
                        sx={{
                          color: 'black',
                          '&:hover': {
                            color: 'white',
                          },
                        }}
                      />}</>}
                    </ListItemButton>
                    {routes &&
                      routes.map((nestedItem) => {
                        return (
                          <Collapse key={nestedItem?.name} in={openedNestedRoute.startsWith(path)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItem
                                key={nestedItem?.name}
                                disablePadding
                                sx={{
                                  display: 'block',
                                  p: 0.7,
                                  pl: open ? 2 : 'auto',
                                }} // Adds margin-bottom for spacing
                              >
                                {roles?.[rolesAvailability]?.[nestedItem?.rolesAvailability]?.read && (
                                  <ListItemButton
                                    sx={{
                                      backgroundColor: `${path}/${nestedItem.path}` === selectedPath ? '#FF4444' : 'transparent',
                                      boxShadow: `${path}/${nestedItem.path}` === selectedPath ? 3 : 'none', // Adds shadow when selected
                                      borderRadius: '10px', // Adds rounded corners when selected
                                      '&:hover': {
                                        backgroundColor: '#FF4444',
                                        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                                          color: 'white',
                                        },
                                        boxShadow: 3, // Adds shadow
                                        borderRadius: '10px', // Adds rounded corners
                                      },
                                    }}
                                    onClick={() => {
                                      const nestedPath = `${path}/${nestedItem.path}`;
                                      navigate(nestedPath);
                                      // setSelectedPath(nestedPath);
                                    }}
                                  >
                                    {nestedItem?.icon && (
                                      <ListItemIcon
                                        sx={{
                                          minWidth: 0,
                                          mr: open ? 1 : 'auto',
                                          justifyContent: 'center',
                                          color: selectedPath.includes(`${path}/${nestedItem.path}`) ? 'white' : 'black',
                                          '&:hover': {
                                            color: 'white',
                                          },
                                        }}
                                      >
                                        {nestedItem.icon}
                                      </ListItemIcon>
                                    )}
                                    <ListItemText
                                      primary={nestedItem?.name}
                                      sx={{
                                        opacity: open ? 1 : 0,
                                        color: selectedPath.includes(`${path}/${nestedItem.path}`) ? 'white' : 'black',
                                        '&:hover': {
                                          color: 'white',
                                        },
                                      }}
                                    />
                                  </ListItemButton>
                                )}
                              </ListItem>
                            </List>
                          </Collapse>
                        );
                      })}
                  </>
                )}
              </ListItem>
            ))}
          </List>
          <Box sx={{ mt: 'auto' }}>
            <List>
              <ListItem
                disablePadding
                sx={{
                  display: 'block',
                }}
              >
                <ListItemButton
                  sx={{
                    'minHeight': 48,
                    'justifyContent': open ? 'initial' : 'center',
                    'backgroundColor': `/logout` === selectedPath ? '#FF4444' : 'transparent',
                    '&:hover': {
                      backgroundColor: '#FF4444',
                      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                        color: 'white',
                      },
                      boxShadow: 3, // Adds shadow
                      borderRadius: '10px', // Adds rounded corners
                    },
                  }}
                  onClick={() => {
                    setShowLogout(true);
                    setOpen(false);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : 'auto',
                      justifyContent: 'center',
                      color: selectedPath === '/logout' ? 'white' : 'black',
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Logout'}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </AppDrawer>
      </Box>
      <LogoutComponent displayModal={showLogout} setDisplayModal={setShowLogout} />
    </>
  );
}
