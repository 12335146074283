import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';
import LeadsView from './views/leads_view';
import EditLeadView from './views/edit_leads_view';

export default function Leads() {
  return (
    <Routes>
      <Route path="/" element={<LeadsView />} />
      <Route path="/:id" element={<EditLeadView />} />
      <Route path='*' element={<ContentNotFound />} />
    </Routes>
  );
}
