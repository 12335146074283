import React from 'react';
import {Route, Routes} from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';

import MealPlanView from './views/meal_plan_view';
import CreateMealPlanView from './views/create_meal_plan_view';
import EditMealPlanView from './views/edit_meal_plan_view';

export default function TiffinRoutes() {
  return (
    <Routes>
        <Route path="/" element={<MealPlanView />}/>
        <Route path="new" element={<CreateMealPlanView />}/>
        <Route path=":id" element = {<EditMealPlanView />} />
        <Route path='*' element ={<ContentNotFound />} />
    </Routes>
  );
}
