import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateRequestOptions, baseQueryWithReAuth } from '../../actions/action_helper';
import { SERVER_URL } from '../../lib/constants';

const baseUrl = `${SERVER_URL}/auth/`;

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'login',
          method: 'POST',
          data,
        }),
      }),
    }),
    logout: builder.mutation({
      queryFn(args, api, extraOptions) {
        return baseQueryWithReAuth({
          ...args, ...generateRequestOptions({
            url: '/auth/logout',
            method: 'POST',
            data: {},
          })
        }, api, extraOptions);
      },
    }),
    refreshToken: builder.mutation({
      queryFn(args, api, extraOptions) {
        return baseQueryWithReAuth({
          ...args,
          ...generateRequestOptions({
            url: '/auth/refresh',
            method: 'POST',
            data: {},
          })
        }, api, extraOptions);
      },
    }),
    codeGeneration: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'generate_code',
          method: 'POST',
          data,
        }),
      }),
    }),
    codeVerification: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'verify_code',
          method: 'POST',
          data,
        }),
      }),
    }),
    registration: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'register',
          method: 'POST',
          data,
        }),
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'forgot_password',
          method: 'POST',
          data,
        }),
      }),
    }),
    preCheck: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'pre-check',
          method: 'POST',
          data,
        }),
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRefreshTokenMutation,
  useCodeGenerationMutation,
  useCodeVerificationMutation,
  useForgotPasswordMutation,
  useRegistrationMutation,
  usePreCheckMutation,
} = authApi;

export default authApi;
