import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Grid, Typography, Link} from '@mui/material';

import {CoreButton, BaseOtpModal, BaseTextField, PublicContainer, Hooks} from '../../../core';
import {useCodeGenerationMutation, useCodeVerificationMutation} from '../../../js/slices/api_slices/auth_api_slice';

const {useSnackBarNotification} = Hooks;

export default function PasswordRecoveryComponent() {
  const [showOtpModal, setShowOtpModal] = useState(false);
  const {register, handleSubmit, getValues, formState: {errors}} = useForm();
  const {errorSnackBar} = useSnackBarNotification();
  const [codeGenerationMutation, {
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useCodeGenerationMutation();

  useEffect(() => {
    if (isSuccess) {
      setShowOtpModal(true);
    }
    if (isError && error) {
      errorSnackBar({message: error?.data?.errorMessage});
    }
  }, [isError, error, isSuccess]);

  const component =
  <Grid container justifyContent='center'>
    <Grid
      item
      component='form'
      container
      sx={{maxWidth: 400, p: 1}}
      spacing={2}
      onSubmit={handleSubmit(async (userInput) => {
        codeGenerationMutation({
          ...userInput,
          type: 'forgotPassword',
        });
      })}
    >
      <Grid item xs={12} container justifyContent='center'>
        <Typography variant="h5" fontWeight="bold" >
        Password Recovery
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent='center'>
        <Typography mb={4} color="secondary.contrastText.main">
        Enter below information to generate OTP
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BaseTextField
          id="email"
          name="email"
          label="Email Address"
          errors={errors}
          validate={register('email', {
            required: 'Email is required',
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <CoreButton
          isLoading={isLoading}
          type='submit'
          variant='contained'
          color="primary"
        >
        Get Otp
        </CoreButton>
      </Grid>
      <Grid
        item
        xs={12}
        container
        sx={{pt: 1}}
      >
        <Typography fontSize={15}>
          <Link href='/login'>Back to Login</Link>
        </Typography>
      </Grid>
    </Grid>
    <BaseOtpModal
      open={showOtpModal}
      handleClose={() => setShowOtpModal(false)}
      userDetails={getValues()}
      type="forgotPassword"
      navigateToRoute='set'
      resendOTPHandler={codeGenerationMutation}
      postGenerationHook={useCodeVerificationMutation}
    />
  </Grid>;

  return (
    <PublicContainer component={component} />
  );
}
