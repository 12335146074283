import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import EmployeeForm from '../components/employee_form';
import { employeesApi, deliveryRoutesApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, TuiSpinner, CoreButton } from '../../../core/index';

const { useUpdateEmployeeMutation, useGetEmployeeQuery } = employeesApi;
const { useGetDeliveryRoutesQuery } = deliveryRoutesApi;

export default function EditEmployeeView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();

  const { data: fetchedData, isLoading: itemLoading } = useGetEmployeeQuery({
    id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const { data: deliveryRouteData, isLoading: deliveryRouteLoading } = useGetDeliveryRoutesQuery({
    'driver.id': id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const startTime = deliveryRouteData?.data?.items?.[0]?.startTime;
  const startTimeMoment = startTime ? moment(startTime, 'HH:mm:ss') : moment().set({ h: 17, m: 0, s: 0 });

  const { register, handleSubmit, formState: { errors, isSubmitSuccessful }, reset, control, getValues, setValue, watch } = useForm({
    defaultValues: {
      ...fetchedData?.data,
      route: {
        ...deliveryRouteData?.data?.items?.[0],
        startTime: startTimeMoment,
      },
    },
  });

  const [updateTiffinDriver, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useUpdateEmployeeMutation();

  useEffect(() => {
    reset({
      ...fetchedData?.data,
      route: {
        ...deliveryRouteData?.data?.items?.[0],
        startTime: startTimeMoment,
      },
    });
  }, [fetchedData?.data, isSubmitSuccessful, deliveryRouteData?.data?.items?.[0]]);

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Tiffin driver modified successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
  }, [isSuccess, isError, data, error]);


  if (itemLoading || deliveryRouteLoading) return <TuiSpinner />;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TuiAppBar title="Edit Employee" />
        </Grid>
        <Grid
          container
          sx={{ mt: 1 }}
          component='form'
          onSubmit={handleSubmit((userInput) => {
            userInput = {
              ...userInput,
              route: {
                ...userInput?.route,
                startTime: moment(userInput.route.startTime).format('HH:mm:ss'),
                id: deliveryRouteData?.data?.items?.[0]?.id,
              },
            };
            updateTiffinDriver({ id, data: userInput });
          })}>
          <Grid item xs={12} container sx={{ p: 1 }}>
            <EmployeeForm
              action='update'
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              control={control}
              register={register}
              errors={errors}
              viewOnly={false}
              item={fetchedData?.data}
            />
          </Grid>
          <Grid item xs={12} container justifyContent='flex-end' spacing={2} alignContent='flex-end' sx={{ bottom: 40, position: 'fixed', right: 20, width: '100%' }}>
            <Grid item>
              <CoreButton
                isLoading={isLoading}
                fullWidth={true}
                onClickHandler={() => navigate(-1)}
                variant="contained"
              >
                Back
              </CoreButton>
            </Grid>
            <Grid item>
              <CoreButton
                isLoading={isLoading}
                type='submit'
                fullWidth={true}
                variant="contained"
              >
                Update
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
