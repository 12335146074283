import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';
import CookingPlanView from './views/cook_pack_view';

export default function Orders() {
  return (
    <Routes>
      <Route path="/" element={<CookingPlanView />} />
      <Route path='*' element={<ContentNotFound />} />
    </Routes>
  );
}
