import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import EmployeeForm from '../components/employee_form';
import { employeesApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, CoreButton } from '../../../core/index';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const { useSnackBarNotification, useSessionStoragePersist } = Hooks;
const { useCreateEmployeeMutation } = employeesApi;

export default function CreateEmployeeView() {
  const navigate = useNavigate();
  let initialData = sessionStorage.getItem(STORAGE_KEYS.EMPLOYEE);
  if (initialData) {
    try {
      let initialJsonData = JSON.parse(initialData);
      initialData = initialJsonData;
    } catch (err) {
      console.error('Error parsing initialData', err);
    }
  }

  const { register, handleSubmit, formState: { errors }, control, getValues, watch } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
  });
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();

  const [addNewEmployee, {
    data: employeeData,
    isSuccess: employeeIsSuccess,
    error: employeeError,
    isError: employeeIsError,
    isLoading: employeeIsLoading,
  }] = useCreateEmployeeMutation();

  useEffect(() => {
    if (employeeIsSuccess && employeeData) {
      successSnackBar({ message: 'Employee created successfully' });
      navigate(-1);
    } if (employeeIsError && employeeError) {
      errorSnackBar({ message: employeeError?.data?.errorMessage });
    }
    return () => {
      if (employeeIsSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.EMPLOYEE);
      }
    }
  }, [employeeIsSuccess, employeeIsError, employeeData, employeeError]);

  useSessionStoragePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.EMPLOYEE });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TuiAppBar title='New Employee' />
        </Grid>
        <Grid
          component='form'
          onSubmit={handleSubmit(async (userInput) => {
            addNewEmployee(userInput);
          })}
          sx={{ mt: 1 }}
          container
        >
          <Grid item xs={12} container sx={{ p: 1, height: 'inherit' }}>
            <EmployeeForm
              action='create'
              getValues={getValues}
              watch={watch}
              control={control}
              register={register}
              errors={errors}
              viewOnly={false}
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            justifyContent='flex-end'
            alignContent='flex-end'
            sx={{ bottom: 40, position: 'fixed', right: 20, width: '100%' }}
          >
            <Grid item>
              <CoreButton
                isLoading={employeeIsLoading}
                fullWidth={true}
                onClickHandler={() => navigate(-1)}
                variant="contained"
              >
                Back
              </CoreButton>
            </Grid>
            <Grid item>
              <CoreButton
                isLoading={employeeIsLoading}
                type='submit'
                fullWidth={true}
                variant="contained"
              >
                Add
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
