import React from 'react';

import CoreButton from '../../../core/button';
import { Grid } from '@mui/material';

const Footer = ({ buttonText, buttonDisabled, buttonClickHandler }) => {
    return (
        <Grid
            container
            sx={{
                position: 'fixed',
                bottom: 20,
                right: 40,
                width: '10vw',
            }}>
            {buttonClickHandler && typeof buttonClickHandler === 'function' &&
                <CoreButton
                    variant="contained"
                    disabled={buttonDisabled}
                    onClickHandler={buttonClickHandler}
                >
                    {buttonText}
                </CoreButton>}
        </Grid>
    );
};

export default Footer;