import {createApi} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat} from '../../actions/action_helper';

const tiffinPaymentApi = createApi({
  reducerPath: 'tiffinPaymentApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    createTiffinPayment: builder.mutation({
      query: ({tiffinSubscriberId, data}) => ({
        ...generateRequestOptions({
          url: `/app/tiffin_payments/${tiffinSubscriberId}`,
          method: 'POST',
          data,
        }),
      }),
    }),
    updatePayment: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: `/app/tiffin_payments/${data?.id}`,
          method: 'PUT',
          data,
        }),
      }),
    }),
    deleteTiffinPayment: builder.mutation({
      query: ({id, data}) => ({
        ...generateRequestOptions({
          url: `/app/tiffin_payments/${id}`,
          method: 'DELETE',
          data,
        }),
      }),
    }),
    getTiffinPayments: builder.query({
      query: (args) => {
        const tiffinSubscriberId = args?.tiffinSubscriberId;
        const updatedArgs = {...args};
        delete updatedArgs?.tiffinSubscriberId;
        return {
          ...generateRequestOptions({
            url: `/app/tiffin_payments/${tiffinSubscriberId}?${objectToQueryFormat(updatedArgs)}`,
            method: 'GET',
          }),
        };
      },
    }),
  }),
});

export const {
  useCreateTiffinPaymentMutation,
  useUpdatePaymentMutation,
  useGetTiffinPaymentsQuery,
  useDeleteTiffinPaymentMutation,
} = tiffinPaymentApi;

export default tiffinPaymentApi;
