import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';
import SettingsTiffinView from './views/settings_general_view';
import SettingsBusinessView from './views/settings_business_view';
import SettingsBillingView from './views/settings_billing_view';
import SettingsCustomerPortalView from './views/settings_customer_portal';

export default function Orders() {
  return (
    <Routes>
      <Route path="billing" element={<SettingsBillingView />} />
      <Route path="general" element={<SettingsTiffinView />} />
      <Route path="customer_portal" element={<SettingsCustomerPortalView />} />
      <Route path="business" element={<SettingsBusinessView />} />
      <Route path='*' element={<ContentNotFound />} />
    </Routes>
  );
}
