import {fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {Mutex} from 'async-mutex';

import {SERVER_URL} from '../lib/constants';
import {logout, setUserDetails} from '../slices/reducer_slices/user_reducer_slice';
const mutex = new Mutex();

const objectToQueryFormat = (requestObject = {}, res = '') => {
  let isContains = false;
  const newRequestObject = {...requestObject};

  if (newRequestObject['contains']) {
    if (typeof newRequestObject['contains'] !== 'object') {
      throw new Error(`Contains must be object ${JSON.stringify(newRequestObject)}`);
    } else {
      const allContainKeys = Object.keys(newRequestObject['contains']);
      allContainKeys.forEach((key, i) => {
        if (newRequestObject['contains'][key]) {
          if (isContains) {
            res += `|${key}=${requestObject['contains'][key]}`;
          } else {
            res += `contains=${key}=${newRequestObject['contains'][key]}`;
            isContains = true;
          }

          // if it is last index add & sign for next queries
          if (i === allContainKeys.length - 1) {
            res += '&';
          }
        }
      });
      delete newRequestObject['contains'];
    }
  }

  const allKeys = Object.keys(newRequestObject);

  allKeys.forEach((key, i) => {
    if (newRequestObject[key]) {
      if (i === allKeys.length - 1) {
        // Do not pass & on last key
        res += `${key}=${newRequestObject[key]}`;
      } else {
        res += `${key}=${newRequestObject[key]}&`;
      }
    }
  });
  return res;
};

const baseQuery = fetchBaseQuery({
  baseUrl: SERVER_URL,
  prepareHeaders: (headers, {getState}) => {
    const accessToken = getState()?.user?.token || localStorage.getItem('token');
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const baseQueryWithReAuth = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // Save original URL and method
        const originalUrl = args.url;
        const originalMethod = args.method;

        args.url = '/auth/refresh';
        args.method = 'POST';
        const refreshResult = await baseQuery(
          args,
          api,
          extraOptions,
        );

        if (refreshResult?.error || !refreshResult?.data || !refreshResult?.data?.token) {
          api.dispatch(logout());
        } else {
          // store the new token
          api.dispatch(setUserDetails(refreshResult.data));

          // Reset URL and method to original
          args.url = originalUrl;
          args.method = originalMethod;

          result = await baseQuery(args, api, extraOptions);
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

const generateRequestOptions = ({url, method, data = null, header={}, removeCredentials= false}) => {

  const options = {
    credentials: 'include',
    url,
    method,
    headers: {
      'device-type': 'web',
      ...header,
    },
  };

  if (removeCredentials) {
    delete options['credentials'];
  }

  if ((method === 'PUT' || method === 'POST') && !data) {
    throw Error('Data is required in put and post');
  }

  if (data) {
    options['body'] = data;
  }

  return options;
};

export {
  objectToQueryFormat,
  generateRequestOptions,
};
