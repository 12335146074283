import React from 'react';
import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';

export default function useSnackBarNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const closeAction = (snackbarKey) => {
    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <Close style={{ color: '#fff', fontSize: '18px' }} />
      </IconButton>
    );
  };
  const getDefaultOptions = (variant, key = Date.now(), autoHideDuration = 1500, action = closeAction, moreOptions = {}) => {
    return {
      variant,
      key,
      autoHideDuration,
      action,
      ...moreOptions,
    };
  };
  const successSnackBar = ({ message, key, autoHideDuration, action, moreOptions }) => {
    enqueueSnackbar(message, getDefaultOptions('success', key, autoHideDuration, action, moreOptions));
  };
  const errorSnackBar = ({ message = 'Internal Server Error', key, autoHideDuration, action, moreOptions }) => {
    enqueueSnackbar(message, getDefaultOptions('error', key, autoHideDuration, action, moreOptions));
  };
  const warnSnackbar = ({ message, key, autoHideDuration, action, moreOptions }) => {
    enqueueSnackbar(message, getDefaultOptions('warning', key, autoHideDuration, action, moreOptions));
  };
  const defaultSnackbar = ({ message, key, autoHideDuration, action, moreOptions }) => {
    enqueueSnackbar(message, getDefaultOptions('default', key, autoHideDuration, action, moreOptions));
  };

  return {
    successSnackBar,
    errorSnackBar,
    warnSnackbar,
    defaultSnackbar,
  };
}
