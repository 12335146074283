import React from 'react';
import {Route, Routes} from 'react-router-dom';

import PasswordRecoveryComponent from './components/password_recovery_component';
import PasswordRecoverySuccessComponent from './components/password_recovery_success';
import PasswordRecoverySetNewComponent from './components/password_recovery_set_new_component';

export default function PasswordRecoveryView() {
  return (
    <Routes>
      <Route path="/" element={<PasswordRecoveryComponent />} />
      <Route path="/set" element={< PasswordRecoverySetNewComponent />} />
      <Route path="/success" element={<PasswordRecoverySuccessComponent />} />
    </Routes>

  );
}
