import {createApi} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions, baseQueryWithReAuth} from '../../actions/action_helper';

const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: 'notifications',
          method: 'GET',
        }),
      }),
    }),
    updateNotifications: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'notifications',
          method: 'PUT',
          data,
        }),
      }),
    }),
  }),
});

export const {useGetNotificationsQuery, useUpdateNotificationssMutation} = notificationsApi;
export default notificationsApi;
