import { useEffect } from 'react';

function useSessionStoragePersist({
    value,
    sessionStorageKey,
}) {
    useEffect(() => {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
    }, [value, sessionStorageKey]);

    return;
}

export default useSessionStoragePersist;
