import { TextField, InputAdornment } from '@mui/material';
import React from 'react';
import Prototypes from 'prop-types';
import { get } from 'react-hook-form';

export default function BaseTextField({
  id,
  name,
  label,
  required = true,
  type,
  helperText,
  errors,
  size = 'medium',
  validate,
  startAdornment,
  endAdornment,
  InputLabelProps,
  ...rest }) {
  return (
    <TextField
      slotProps={{
        inputLabel: { ...InputLabelProps, shrink: true },
        input: {
          startAdornment: startAdornment && (
            <InputAdornment position="start">
              {startAdornment}
            </InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">
              {endAdornment}
            </InputAdornment>
          ),
        }
      }}
      fullWidth
      required={required}
      id={id}
      size={size}
      type={type}
      label={label}
      error={!!get(errors, name)}
      helperText={get(errors, name)?.message || helperText}
      {...validate}
      {...rest}
    />
  );
}

BaseTextField.prototypes = {
  id: Prototypes.string.isRequired,
  label: Prototypes.string.isRequired,
  name: Prototypes.string.isRequired,
  required: Prototypes.bool.isRequired,
};
