import React, { useEffect, useState } from 'react';
import {
  Grid2 as Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider
} from '@mui/material';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import { tiffinsApi } from '../../../js/slices/api_slices';
import { TuiAppBar, Table, DatePicker, TuiSpinner, Hooks, CoreButton, ConfirmationModal } from '../../../core';
import { capitalizeFirstLetter, getFormattedDateFromUnixTimestamp, getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';
import DinespotLogo from '../../../assets/images/dinespot_black_logo.png';
import { DataGrid } from '@mui/x-data-grid';

const { useGetTiffinCookingPlanQuery } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

const tiffinItemsHeadCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'quantity',
  title: 'Quantity',
}, {
  id: 'unit',
  title: 'Unit',
}, {
  id: 'packets',
  title: 'Packets',
}];

const packingListHeadCells = [{
  field: 'index',
  headerName: 'No.',
  width: 20,
  editable: false
}, {
  field: 'name',
  headerName: 'Name',
  flex: 1,
  editable: false
}, {
  field: 'address',
  headerName: 'Address',
  flex: 3,
  editable: false
}, {
  field: 'tiffin',
  headerName: 'Meal Plan',
  flex: 1,
  editable: false
}, {
  field: 'changedItems',
  headerName: 'Changed Items',
  flex: 2,
  editable: false
}, {
  field: 'comments',
  headerName: 'Comment',
  flex: 2,
  editable: false,
  renderCell: (params) => (
    <Typography
      component="div"
      sx={{ whiteSpace: 'pre-line' }} // To break the line in the cell
    >
      {params.value}
    </Typography>
  )
}];

const tiffinHeadCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'quantity',
  title: 'Quantity',
}];

export default function TiffinsCookingPlan() {
  const [loading, setLoading] = useState(false);
  const [cookingPlanDownload, setCookingPlanDownload] = useState(false);
  const [packingListDownload, setPackingListDownload] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [packingList, setPackingList] = useState({
    homeDelivery: [],
    pickUp: [],
  });
  const [cookingPlan, setCookingPlan] = useState({
    overall: {
      meals: [],
      mealItems: [],
    },
    homeDelivery: {
      meals: [],
      mealItems: [],
    },
    pickUp: {
      meals: [],
      mealItems: [],
    },
  });
  const [cookingPlanExpanded, setCookingPlanExpanded] = useState(true);
  const [packingListExpanded, setPackingListExpanded] = useState(true);
  const { successSnackBar } = useSnackBarNotification();

  const {
    data,
    isSuccess,
    isFetching,
  } = useGetTiffinCookingPlanQuery({
    date: moment(selectedDate).endOf('day').valueOf(),
  }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccess && data) {
      const { cookingPlan, packingList } = data?.data;
      setCookingPlan(cookingPlan);
      setPackingList(packingList);
    }
  }, [data, isSuccess]);


  const handleCookingPlanDownload = () => {
    setLoading(true);
    const { overall, homeDelivery, pickUp } = cookingPlan;
    if (cookingPlan?.overall?.meals?.length) {
      const doc = new jsPDF();
      doc.setFontSize(16);
      doc.setTextColor(128, 128, 128);
      let text = `Cooking Plan: ${getFormattedDateFromUnixTimestamp(selectedDate, 'DD MMMM (dddd)')}`;
      const centerOfPage = 105;
      let y = 10;
      doc.text(text, centerOfPage, y, { align: 'center' });
      let textWidth = doc.getTextWidth(text);
      doc.setDrawColor(128, 128, 128);
      doc.setLineWidth(0.5);
      doc.line(centerOfPage - textWidth / 2, ++y, centerOfPage + textWidth / 2, y);

      doc.setFontSize(14);
      y = 20;
      text = 'Overall';
      doc.text(text, centerOfPage, y, { align: 'center' });
      textWidth = doc.getTextWidth(text)
      doc.line(centerOfPage - textWidth / 2, ++y, centerOfPage + textWidth / 2, y);
      y += 5;
      autoTable(doc, {
        theme: 'grid',
        columnStyles: { 0: { cellWidth: 45 }, 1: { cellWidth: 25 } },
        head: [tiffinHeadCells.map(({ title }) => title)],
        body: overall?.meals?.length ? overall.meals.map(({ name, quantity }) => [name, quantity]) : [],
        foot: [['Total', overall?.meals?.reduce((acc, { quantity }) => acc + quantity, 0)]],
        startY: y,
        margin: { left: 10, bottom: 15 },
        styles: { cellPadding: 4 },
        tableWidth: 'wrap',
      });

      autoTable(doc, {
        theme: 'grid',
        columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 25 }, 2: { cellWidth: 30 }, 3: { cellWidth: 30 } },
        head: [tiffinItemsHeadCells.map(({ title }) => title)],
        body: overall?.mealItems?.length ? overall?.mealItems?.map(({ name, quantity, unit, packetDetails }) =>
          [
            capitalizeFirstLetter(name),
            quantity,
            unit,
            packetDetails?.map(({ quantityPerPacket, packets }) => {
              return `${quantityPerPacket} x ${packets} (Packets)`;
            }).join('\n')
          ]) : [],
        startY: y,
        margin: { left: 90, bottom: 15 },
        styles: { cellPadding: 4 },
        tableWidth: 'wrap',
      });

      homeDelivery.meals.forEach((item, index) => {
        const { route, items } = item;
        if (items?.length) {
          doc.addPage();
          y = 20;
          text = route?.name ? `Route name: ${route?.name}` : 'Without Route';
          doc.text(text, centerOfPage, y, { align: 'center' });
          textWidth = doc.getTextWidth(text);
          doc.line(centerOfPage - textWidth / 2, ++y, centerOfPage + textWidth / 2, y);
          y += 5;

          autoTable(doc, {
            theme: 'grid',
            columnStyles: { 0: { cellWidth: 45 }, 1: { cellWidth: 25 } },
            head: [tiffinHeadCells.map(({ title }) => title)],
            body: items?.length ? items.map(({ name, quantity }) => [name, quantity]) : [],
            foot: [['Total', items?.reduce((acc, { quantity }) => acc + quantity, 0)]],
            startY: y,
            margin: { left: 10, bottom: 15 },
            styles: { cellPadding: 4 },
            tableWidth: 'wrap',
          });

          const mealItemsInRoute = homeDelivery.mealItems.find((mealItem) => mealItem.route?.id === route?.id)?.items;

          autoTable(doc, {
            theme: 'grid',
            columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 25 }, 2: { cellWidth: 30 }, 3: { cellWidth: 30 } },
            head: [tiffinItemsHeadCells.map(({ title }) => title)],
            body: mealItemsInRoute?.length ? mealItemsInRoute.map(({ name, quantity, unit, packetDetails }) =>
              [
                capitalizeFirstLetter(name),
                quantity,
                unit,
                packetDetails?.map(({ quantityPerPacket, packets }) => {
                  return `${quantityPerPacket} x ${packets} (Packets)`;
                }).join('\n')
              ]) : [],
            startY: y,
            margin: { left: 90, bottom: 15 },
            styles: { cellPadding: 4 },
            tableWidth: 'wrap',
          });
        }
      });

      if (pickUp?.meals?.length) {
        doc.addPage();
        y = 20;
        text = 'Pick Up';
        doc.text(text, centerOfPage, y, { align: 'center' });
        textWidth = doc.getTextWidth(text)
        doc.line(centerOfPage - textWidth / 2, ++y, centerOfPage + textWidth / 2, y);
        y += 5;

        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 45 }, 1: { cellWidth: 25 } },
          head: [tiffinHeadCells.map(({ title }) => title)],
          body: pickUp?.meals?.length ? overall.meals.map(({ name, quantity }) => [name, quantity]) : [],
          foot: [['Total', pickUp?.meals?.reduce((acc, { quantity }) => acc + quantity, 0)]],
          startY: y,
          margin: { left: 10, bottom: 15 },
          styles: { cellPadding: 4 },
          tableWidth: 'wrap',
        });

        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 25 }, 2: { cellWidth: 30 }, 3: { cellWidth: 30 } },
          head: [tiffinItemsHeadCells.map(({ title }) => title)],
          body: pickUp?.mealItems?.length ? pickUp?.mealItems?.map(({ name, quantity, unit, packetDetails }) =>
            [
              capitalizeFirstLetter(name),
              quantity,
              unit,
              packetDetails?.map(({ quantityPerPacket, packets }) => {
                return `${quantityPerPacket} x ${packets} (Packets)`;
              }).join('\n')
            ]) : [],
          startY: y,
          margin: { left: 90, bottom: 15 },
          styles: { cellPadding: 4 },
          tableWidth: 'wrap',
        });
      }

      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        var img = new Image();
        img.src = DinespotLogo;
        doc.addImage(img, 'PNG', 10, 285, 20, 5);
        doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
        doc.text(centerOfPage, 288, getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD'), { align: 'center' });
      }

      doc.save(`cooking_plan_${getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD')}.pdf`);
    } else {
      successSnackBar({ message: 'No data available.' });
    }
    setLoading(false);
  };

  const handlePackingListDownload = async () => {
    setLoading(true);
    if (packingList?.homeDelivery?.length || packingList?.pickUp?.length) {
      const doc = new jsPDF();
      doc.setFontSize(16);
      doc.setTextColor(128, 128, 128);
      let text = `Packing List: ${getFormattedDateFromUnixTimestamp(selectedDate, 'DD MMMM (dddd)')}`;
      const x = 105;
      let y = 10;
      doc.text(text, x, y, { align: 'center' });
      let textWidth = doc.getTextWidth(text);
      doc.setDrawColor(128, 128, 128);
      doc.setLineWidth(0.5);
      doc.line(x - textWidth / 2, ++y, x + textWidth / 2, y);
      const headers = ['#', 'Name', 'Tiffin', 'Item Changes', 'Comment'];

      y += 10;

      packingList.homeDelivery.forEach((item, index) => {
        const mealCount = item?.customers?.length || 0;
        if (index !== 0) {
          if (mealCount <= 0) {
            return;
          }
          doc.addPage();
        }

        doc.setFontSize(18);
        let routeName = item?.route?.name ? `Route name: ${item?.route?.name}` : 'Without Route';
        routeName += ` (${mealCount} Meal${mealCount === 1 ? '' : 's'})`;
        const routeY = index === 0 ? y : 10;
        doc.text(routeName, x, routeY, { align: 'center' });
        const textWidth = doc.getTextWidth(routeName);
        const underlineY = routeY + 1;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);
        doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

        const tiffinData = {};
        const tableData = [];
        item.customers.forEach((customer, stopIndex) => {
          const { tiffin } = customer;
          const { changedItems } = tiffin;
          if (tiffinData[tiffin?.name]) {
            tiffinData[tiffin?.name] = tiffinData[tiffin?.name] + 1;
          } else {
            tiffinData[tiffin?.name] = 1;
          }

          tableData.push([
            stopIndex + 1,
            `${customer?.firstName} ${customer?.lastName}`,
            tiffin?.name,
            changedItems?.length
              ? [...changedItems]
                .sort((a, b) => a.status.localeCompare(b.status))
                .map(item => `${item?.status} - ${capitalizeFirstLetter(item?.name)} (${item?.quantity} ${item?.unit?.toLowerCase()})`)
                .join('\n')
              : '',
            customer?.comment
          ]);
        });

        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 10 }, 1: { cellWidth: 50 }, 2: { cellWidth: 30 }, 3: { cellWidth: 50 }, 4: { cellWidth: 50 } },
          head: [headers],
          body: tableData,
          startY: routeY + 10,
          margin: { left: 10, right: 10, bottom: 15 },
          styles: { cellPadding: 3 },
        });
      });

      const pickUpMealCount = packingList?.pickUp?.length || 0;
      if (pickUpMealCount) {
        doc.addPage();
        y = 10;
        text = 'Pick Up';
        text += ` (${pickUpMealCount} Meal${pickUpMealCount === 1 ? '' : 's'})`;
        doc.text(text, x, y, { align: 'center' });
        textWidth = doc.getTextWidth(text);
        doc.line(x - textWidth / 2, y + 1, x + textWidth / 2, y + 1);
        y += 5;

        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 10 }, 1: { cellWidth: 50 }, 2: { cellWidth: 30 }, 3: { cellWidth: 50 }, 4: { cellWidth: 50 } },
          head: [headers],
          body: packingList.pickUp.map((customer, index) => {
            const { tiffin } = customer;
            const { changedItems } = tiffin;
            return [
              index + 1,
              `${customer?.firstName} ${customer?.lastName}`,
              tiffin?.name,
              changedItems?.length
                ? [...changedItems]
                  .sort((a, b) => a.status.localeCompare(b.status))
                  .map(item => `${item?.status} - ${item?.name} (${item?.quantity} ${item?.unit?.toLowerCase()})`)
                  .join('\n')
                : '',
              customer?.comment
            ];
          }),
          startY: y + 5,
          margin: { left: 10, right: 10, bottom: 15 },
          styles: { cellPadding: 3 },
        });
      }

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(8);
      doc.setTextColor(128, 128, 128);
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        var img = new Image();
        img.src = DinespotLogo;
        doc.addImage(img, 'PNG', 10, 285, 20, 5);
        doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
        doc.text(x, 288, getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD'), { align: 'center' });
      }

      doc.save(`packing_list_${getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD')}.pdf`);
    }
    setLoading(false);
  };

  const CookingPlanContent = ({ title, content, total }) => {
    const { mealItems, meals } = content;
    return <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {title}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>{`${total} Meals`}</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid container spacing={3} justifyContent='center'>
          <Grid size={4}>
            <Table headers={tiffinHeadCells} data={meals} sort={true} />
          </Grid>
          <Grid size={5}>
            <Table headers={tiffinItemsHeadCells} data={mealItems?.map((mealItem) => {
              return {
                name: capitalizeFirstLetter(mealItem?.name),
                quantity: mealItem?.quantity,
                unit: mealItem?.unit,
                packets: mealItem?.packetDetails?.map(({ quantityPerPacket, packets }) => `${quantityPerPacket} x ${packets} (Packets)`).join('\n')
              }
            })} sort={true} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  }

  const PackingListHomeDeliveryContent = ({ title, item }) => {
    const totalCustomers = item?.customers?.length;
    const processedItems = item?.customers?.map((customer, index) => {
      return {
        id: customer?.id,
        index: index + 1,
        name: `${customer?.firstName} ${customer?.lastName}`,
        address: customer?.address,
        tiffin: customer?.tiffin?.name,
        changedItems: customer?.tiffin?.changedItems?.length
          ? customer?.tiffin?.changedItems?.map(({ status, name, quantity, unit }) => `${status} - ${name} (${quantity} ${unit})`).join('\n')
          : '',
        comments: customer?.comment,
      }
    });
    return <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-panel-content`}
        id={`${title}-panel-header`}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {title}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>{`${totalCustomers} Customers`}</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <DataGrid
          rows={processedItems}
          columns={packingListHeadCells}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 20, 50]} // Add support for rows per page
        />
      </AccordionDetails>
    </Accordion>
  }

  const cookingPlanAccordian = <Accordion expanded={cookingPlanExpanded} onChange={() => setCookingPlanExpanded(!cookingPlanExpanded)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="cooking-panel-content"
      id="cooking-panel-header"
    >
      <Typography>Cooking Plan</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <CookingPlanContent
        title='Overall'
        content={cookingPlan?.overall}
        total={cookingPlan?.overall?.meals?.reduce((total, meal) => total + meal.quantity, 0)}
      />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="home-delivery-panel-content"
          id="home-delivery-panel-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Home Delivery</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {`${cookingPlan?.homeDelivery?.meals.reduce((total, route) => total + route?.items?.reduce((sum, tiffin) => sum + tiffin.quantity, 0), 0)} Meals in ${cookingPlan?.homeDelivery?.meals?.length} Routes`}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {cookingPlan?.homeDelivery?.meals.map((meal) => {
            const routeName = meal?.route?.id ? `Route name: ${meal?.route?.name}` : 'Without Route';
            const mealItems = cookingPlan?.homeDelivery?.mealItems?.find((item) => meal?.route?.id === item?.route?.id)?.items;
            const total = meal?.items?.reduce((total, item) => total + item?.quantity, 0);
            return (
              <Grid key={meal?.id} size={12} sx={{ mt: 1 }}>
                <CookingPlanContent
                  title={routeName}
                  content={{
                    meals: meal?.items,
                    mealItems,
                  }}
                  total={total}
                />
              </Grid>
            )
          }
          )}
        </AccordionDetails>
      </Accordion>
      <CookingPlanContent
        title='Pickup'
        content={cookingPlan?.pickUp}
        total={cookingPlan?.pickUp?.meals?.length}
      />
    </AccordionDetails>
  </Accordion>;

  const packingListAccordian = <Accordion expanded={packingListExpanded} onChange={() => setPackingListExpanded(!packingListExpanded)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="packing-list-panel-content"
      id="packing-list-panel-header"
    >
      <Typography>Packing List</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="home-delivery-panel-content"
          id="home-delivery-panel-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Home Delivery</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {`${packingList?.homeDelivery?.reduce((total, route) => total + route?.customers?.length, 0)} Customers in ${packingList?.homeDelivery?.length} Routes`}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {packingList?.homeDelivery?.map((item, index) => (
            <Grid key={item?.id} size={12} sx={{ mt: 1 }}>
              <PackingListHomeDeliveryContent
                title={item?.route?.id ? `Route name: ${item?.route?.name}` : 'Without Route'}
                item={item}
              />
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="pick-up-panel-content"
          id="pick-up-panel-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Pick Up</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {`${packingList?.pickUp?.length} Customers`}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <DataGrid
            rows={packingList?.pickUp?.map((customer, index) => {
              return {
                id: customer?.id,
                index: index + 1,
                name: `${customer?.firstName} ${customer?.lastName}`,
                address: customer?.address,
                tiffin: customer?.tiffin?.name,
                changedItems: customer?.tiffin?.changedItems?.length
                  ? customer?.tiffin?.changedItems?.map(({ status, name, quantity, unit }) => `${status} - ${name} (${quantity} ${unit})`).join('\n')
                  : '',
                comments: customer?.comment,
              }
            })}
            columns={packingListHeadCells}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 20, 50]} // Add support for rows per page
          />
        </AccordionDetails>
      </Accordion>
    </AccordionDetails>
  </Accordion>;

  const downloadModal = <ConfirmationModal
    open={showDownloadModal}
    handleClose={() => setShowDownloadModal(false)}
    title={
      <Grid container spacing={1}>
        <Grid size={12}>
          <Typography variant="h6">Select and click on Download</Typography>
        </Grid>
        <FormGroup>
          <FormControlLabel
            label="Cooking Plan"
            control={
              <Checkbox
                checked={cookingPlanDownload}
                onChange={(event) => setCookingPlanDownload(event.target.checked)}
                sx={{
                  color: 'secondary.contrastText',
                  '&.Mui-checked': {
                    color: 'secondary.contrastText',
                  },
                }}
              />
            }
          />
          <FormControlLabel
            label="Packing List"
            control={
              <Checkbox
                checked={packingListDownload}
                onChange={(event) => setPackingListDownload(event.target.checked)}
                sx={{
                  color: 'secondary.contrastText',
                  '&.Mui-checked': {
                    color: 'secondary.contrastText',
                  },
                }}
              />
            }
          />
        </FormGroup>
      </Grid>
    }
    type="Download"
    actionHandler={() => {
      if (cookingPlanDownload) {
        handleCookingPlanDownload();
      }
      if (packingListDownload) {
        handlePackingListDownload();
      }
      setShowDownloadModal(false);
    }}
  />;

  return (<>
    {isFetching && <TuiSpinner />}
    {!isFetching && <Grid container spacing={1}>
      <Grid container size={12}>
        <Grid size={3}>
          <TuiAppBar title="Cook & Pack" />
        </Grid>
        <Grid size={9} sx={{ mt: 1 }} container justifyContent='flex-end' spacing={1}>
          <Grid size='auto'>
            <DatePicker
              size='small'
              onChange={(event) => {
                setSelectedDate(moment(event));
              }}
              value={selectedDate}
              label="Date"
            />
          </Grid>
          <Grid size="auto">
            <CoreButton
              isLoading={loading}
              fullWidth={false}
              disabled={false}
              variant='outlined'
              startIcon={<DownloadIcon />}
              onClickHandler={() => setShowDownloadModal(!showDownloadModal)}
            >
              Download
            </CoreButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12} sx={{ mt: 2 }}>
        {cookingPlanAccordian}
      </Grid>
      <Grid size={12} sx={{ mt: 2 }}>
        {packingListAccordian}
      </Grid>
    </Grid>}
    {downloadModal}
  </>
  );
}
