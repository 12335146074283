import React from 'react';
import {Navigate, Route, Routes, BrowserRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {
  Login,
  Signup,
  HomePage,
  PasswordRecovery,
} from '../views/index';

const PrivateRoute = function({view}) {
  const storeToken = useSelector((store) => store.user.token);
  const localToken = localStorage.getItem('token');
  const token = storeToken || localToken;
  return token ? view : <Navigate to="/login" replace />;
};

const RoutingIndex = function() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password/*" element={<PasswordRecovery />} />
        <Route path="/*" element= {<PrivateRoute view = {<HomePage/>} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutingIndex;
