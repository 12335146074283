import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Modal, Typography, Grid} from '@mui/material';

import BaseTextField from './base_text_field';
import CoreButton from './button';
import useSnackBarNotification from './hooks/use_snack_bar_notification';

export default function BaseOtpModal({
  open,
  handleClose,
  userDetails,
  type,
  resendOTPHandler,
  navigateToRoute,
  postGenerationHook,
  postGenerationHandler}) {
  const [otpTimer, setOtpTimer] = useState(30);
  const [otpMismatch, setOtpMismatch] = useState('');
  const {register, handleSubmit, formState: {errors}, setValue, getValues} = useForm();
  const [postGenerationAction, {
    data,
    isSuccess,
    isError,
    error,
    isLoading,
  }] = postGenerationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resendOTPClickHandler = () => {
    resendOTPHandler({...userDetails, type});
    setOtpTimer(30);
  };
  const {successSnackBar} = useSnackBarNotification();

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpTimer > 0 && open) {
        setOtpTimer(otpTimer - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [otpTimer, open]);

  useEffect(() => {
    if (isSuccess && data) {
      postGenerationHandler && dispatch(postGenerationHandler(data.data));
      navigate(navigateToRoute, {
        state: {
          code: getValues('code'),
          ...userDetails,
        },
        replace: true,
      });
      if (type === 'registration') {
        successSnackBar({
          message: 'Registration Successfully Done. Please login to Continue',
          key: 'registration-success',
          autoHideDuration: 3000,
          moreOptions: {}, // Add any additional options here
        });
      }
    }
    if (isError && error) {
      setOtpMismatch(error?.data?.errorMessage);
    }
  }, [data, isSuccess, isLoading, isError, error]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    color: 'primary.main',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 4,
  };

  return (
    <Modal
      open={open}
      aria-labelled-by="modal-modal-title"
      aria-described-by="modal-modal-description"
    >
      <Grid
        spacing={2}
        component='form'
        onSubmit={handleSubmit(({code}) => {
          postGenerationAction({code, ...userDetails, type});
        })}
        sx={style}>
        <Grid
          item
          xs={12}
          container
          justifyContent='center'
          alignItems='center'
        >
          <Typography fontSize="30px" fontWeight='bold'>OTP Verification</Typography>
          <Typography fontSize='15px' color="secondary.contrastText.main">Enter the one time password sent to your email</Typography>
          <Typography fontSize='15px' color='secondary.contrastText'>{userDetails.email}</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          rowSpacing={1}
          container
          justifyContent='center'
          alignItems='center'
          sx={{
            mt: 4,
          }}
        >
          <Grid item xs={12}>
            <BaseTextField
              size='small'
              id="code"
              name='code'
              autoFocus
              placeholder="o     o     o     o     o     o "
              sx={{
                'pb': 0,
                'input': {
                  textAlign: 'center',
                },
              }}
              errors={errors}
              validate={register('code', {
                required: 'Otp is required',
                minLength: {
                  value: 6,
                  message: 'OTP must be 6 digits',
                },
              })}
            />
          </Grid>
          <Grid item xs={12}>
            {otpMismatch && <Typography fontSize={12} color="secondary.contrastText">{otpMismatch}</Typography>}
          </Grid>
          <Grid
            item
            sx={{
              fontSize: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography fontSize="inherit">{'Didn\'t receive the OTP?'}</Typography>
            <CoreButton
              variant='text'
              disableRipple
              disabled={otpTimer !== 0}
              size="small"
              onClickHandler={resendOTPClickHandler}
              sx={{
                'p': 0,
                'width': '30%',
                'color': 'secondary.contrastText',
                'textTransform': 'none',
                'textDecoration': 'underline',
                'fontSize': 'inherit',
              }}
            >Resend OTP</CoreButton>
            <Typography fontSize="inherit">&nbsp;in {otpTimer}s</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          rowSpacing={2}
          container
          justifyContent='center'
          alignItems='center'
          sx={{
            mt: 4,
          }}
        >
          <Grid item xs={12}>
            <CoreButton
              variant='contained'
              isLoading={isLoading}
              type='submit'
            >
            Verify
            </CoreButton>
          </Grid>
          <Grid item xs={12}>
            <CoreButton
              variant='outlined'
              sx={{
                'backgroundColor': 'secondary.contrastText.light',
                ':hover': {
                  backgroundColor: 'white',
                },
              }}
              onClickHandler={() => {
                handleClose();
                setOtpMismatch('');
                setOtpTimer(30);
                setValue('code', '');
              }}
            >
            Cancel
            </CoreButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
