import React from 'react';
import {
  Drawer,
  Grid2 as Grid,
  Typography,
  IconButton,
  ListItemText,
  List,
  ListItem,
  Box,
  Divider
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import { TuiSpinner, DataNotFound } from '../../../core';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';

const { useGetTiffinSubscriberQuery } = tiffinsApi;

export default function CustomerHistoryDrawer({ open, handleClose, item }) {

  const { data,
    isLoading } = useGetTiffinSubscriberQuery({
      id: item?.id,
    }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open) });


  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: { width: 850 },
      }}
      open={open}
      onClose={handleClose}
    >
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid container size={12}>
          <Grid size={1}>
            <IconButton onClick={handleClose} sx={{ p: 0, pr: 1, pt: 0.5 }}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid size={4}>
            <Typography variant="h6" fontWeight="bold">
              Changes History
            </Typography>
          </Grid>
        </Grid>
        {isLoading && <TuiSpinner />}
        {!isLoading && <Grid size={12}>
          {!data?.data?.changesHistory?.length && <DataNotFound title="History"/>}
          {data?.data?.changesHistory?.length > 0 && (
            <List>
              {data.data.changesHistory.map(({ createdBy, action, fieldName, newValue, oldValue, date }, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        <>
                          <Typography component="span" color="text.primary">
                            {`${createdBy?.firstName} ${createdBy?.lastName} ${action} the `}
                            <Typography component="span" fontWeight="bold">
                              {fieldName}
                            </Typography>
                            <Typography component="span" color="text.secondary" variant="body2" sx={{ ml: 1 }}>
                              {getFormattedDateTimeFromUnixTimestamp(date)}
                            </Typography>
                          </Typography>
                        </>
                      }
                      secondary={
                        <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                          <Typography component="span" variant="body2" color="text.secondary">
                            {oldValue}
                          </Typography>
                          <Box display="flex" alignItems="center" mx={1}>
                            <EastIcon fontSize="small" />
                          </Box>
                          <Typography component="span" variant="body2" color="text.primary">
                            {newValue}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  {index < data.data.changesHistory.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          )}
        </Grid>}
      </Grid>
    </Drawer>
  );
}