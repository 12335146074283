import {createApi} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat} from '../../actions/action_helper';

const shippingStatusApi = createApi({
  reducerPath: 'shippingStatusApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    updateShippingStatus: builder.mutation({
      query: ({tiffinSubscriberId, data}) => ({
        ...generateRequestOptions({
          url: `/app/shipping_status/${tiffinSubscriberId}`,
          method: 'PUT',
          data,
        }),
      }),
    }),
    getShippingStatus: builder.query({
      query: (args) => {
        const tiffinSubscriberId = args?.tiffinSubscriberId;
        const updatedArgs = {...args};
        delete updatedArgs?.tiffinSubscriberId;
        return {
          ...generateRequestOptions({
            url: `/app/shipping_status/${tiffinSubscriberId}?${objectToQueryFormat(updatedArgs)}`,
            method: 'GET',
          }),
        };
      },
    }),
  }),
});

export const {
  useLazyGetShippingStatusQuery,
  useUpdateShippingStatusMutation,
} = shippingStatusApi;

export default shippingStatusApi;
