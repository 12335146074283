import { TIMEZONES } from './constants';
import moment from 'moment';

export const capitalizeFirstLetter = (string) => {
  if (!string) return;
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getFormattedPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '--';
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

export const buildFormData = (formData, data, parentKey) => {
  if (data === null || data === undefined) {
    return;
  }

  if (Array.isArray(data) && data.length === 0) {
    formData.append(parentKey, null);
    return;
  }
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data === null && typeof data === 'string' ? '' : data;
    formData.append(parentKey, value);
  }
};

export const isEligible = (path, action, roles) => {
  if (!roles || !path || !action) {
    return false;
  }

  const foundRoles = roles.data && roles.data.roles ? roles.data.roles : roles;
  if (path === '/menu-items') {
    path = 'menuItems';
  } else {
    path = path?.split('/')[1];
  }

  if (foundRoles?.[path]?.[action]) {
    return true;
  }
  return false;
};

export const getFormattedDateFromUnixTimestamp = (timestamp, dateFormat = 'DD MMM YYYY') => {
  return moment(timestamp).format(dateFormat);
};

export const getFormattedTimeFromUnixTimestamp = (timestamp, showSeconds = false, show24HourFormat = false) => {
  const format = show24HourFormat
    ? showSeconds ? 'HH:mm:ss' : 'HH:mm'
    : showSeconds ? 'hh:mm:ss A' : 'hh:mm A';
  return moment(timestamp).format(format);
};

export const getFormattedDateTimeFromUnixTimestamp = (timestamp, showSeconds = false, show24HourFormat = false, dateFormat = 'DD MMM YYYY') => {
  const date = getFormattedDateFromUnixTimestamp(timestamp, dateFormat);
  const time = getFormattedTimeFromUnixTimestamp(timestamp, showSeconds, show24HourFormat);
  return `${date} ${time}`;
};

export const getTagSrc = (tag) => {
  const output = TAGS.filter((item) => item.name === tag);
  return output[0]?.icon;
};

export const getDaysOfWeekBetweenDates = (sDate, eDate, matchingDays = []) => {
  const startDate = moment(sDate);
  const endDate = moment(eDate);

  endDate.add(1, 'day');

  let daysOfWeekCount = 0;

  while (startDate < endDate) {
    if (matchingDays.includes(startDate.day())) {
      daysOfWeekCount++;
    }
    startDate.add(1, 'day');
  }

  return daysOfWeekCount;
};

export const getTimezone = (timezone) => {
  const extractedTimezone = Object.entries(TIMEZONES).find(([key, value]) => value === timezone)?.[0];
  timezone = `America/${extractedTimezone}`;
  return timezone;
};