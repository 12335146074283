import {createApi} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat} from '../../actions/action_helper';

const employeesApi = createApi({
  reducerPath: 'Employees Api',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    createEmployee: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'app/employees',
          method: 'POST',
          data,
        }),
      }),
    }),
    updateEmployee: builder.mutation({
      query: ({id, data}) => ({
        ...generateRequestOptions({
          url: `/app/employees/${id}`,
          method: 'PUT',
          data,
        }),
      }),
    }),
    deleteEmployee: builder.mutation({
      query: ({id}) => ({
        ...generateRequestOptions({
          url: `/app/employees/${id}`,
          method: 'DELETE',
        }),
      }),
    }),
    getEmployees: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/employees?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getEmployee: builder.query({
      query: ({id}) => ({
        ...generateRequestOptions({
          url: `/app/employees/${id}`,
          method: 'GET',
        }),
      }),
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useLazyGetEmployeeQuery,
  useLazyGetEmployeesQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
} = employeesApi;

export default employeesApi;
