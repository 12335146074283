import React from 'react';
import {
    Drawer,
} from '@mui/material';
import { TuiSpinner, GoogleMap } from '../../../core';
import { TIFFIN } from '../../../js/lib/constants';

export default function MapViewDrawer({ item, stops, open, handleClose, isLoading = false }) {
    stops = stops?.filter((stop) => stop?.status !== TIFFIN.DELIVERY_ROUTES_STOP_STATUS.CANCELLED);
    const markers = [
        {
            lat: item?.startAddress?.coordinates?.latitude,
            lng: item?.startAddress?.coordinates?.longitude,
            position: 0,
            title: `${item?.startAddress?.description} - Start`
        },
        ...stops?.map((stop) => ({
            title: `${stop?.address?.description} - ${stop?.status}`,
            position: stop?.position + 1,
            lat: stop?.address?.coordinates?.latitude,
            lng: stop?.address?.coordinates?.longitude,
            status: stop?.status,
            unassigned: stop?.unassigned
        }))
    ];

    if (item?.finishAddress?.id) {
        markers.push({
            title: `${item?.startAddress?.description} - Finish`,
            position: stops.length + 1,
            lat: item?.finishAddress?.coordinates?.latitude,
            lng: item?.finishAddress?.coordinates?.longitude
        })
    }

    return (
        <Drawer
            anchor="right"
            PaperProps={{
                sx: {
                    width: '60vw'
                },
            }}
            open={open}
            onClose={handleClose}>
            {isLoading && <TuiSpinner />}
            {item && stops?.length > 0 && <GoogleMap
                width='60vw'
                height='95vh'
                startAddress={item?.startAddress}
                markers={markers}
                geometry={item?.geometry}
            />}
        </Drawer>
    );
}