import React from 'react';
import {
    Drawer,
    Grid,
    Typography,
    Box,
} from '@mui/material';

import { Table } from '../../../core';
import { DAYS } from '../../../js/lib/constants';

const headCells = [{
    id: 'name',
    title: 'Name',
}, {
    id: 'quantity',
    title: 'Quantity',
}, {
    id: 'unit',
    title: 'Unit',
}, {
    id: 'days',
    title: 'Days',
}];

export default function TiffinGoodsDrawer({ item, open, handleClose }) {
    const processedItems = item?.tiffin?.items?.map((item) => {
        const { id, name, quantity, unit, days } = item;
        return {
            name,
            quantity,
            unit,
            days: days.map((day) => DAYS.find(({ value }) => value === day).shortName).join(', '),
            id,
        };
    });

    return (
        <Drawer
            anchor="right"
            PaperProps={{
                sx: { width: '40vw' },
            }}
            open={open}
            onClose={handleClose}>
            <Grid
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    height: '100%',
                }}>
                <Grid item xs={12} container spacing={1}
                    sx={{
                        p: 2,
                        height: 'inherit',
                    }}>
                    <Grid item xs={12} container height='0%' spacing={1}>
                        <Grid item xs={12} container>
                            <Grid item xs={6}>
                                <Typography fontWeight="bold" variant="h6">Items</Typography>
                                <Typography variant="body">{`${item?.customer?.firstName} / ${item?.tiffin?.name}`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display="flex" flexDirection="column" alignItems="stretch" padding={1}
                                sx={{
                                    'height': '90vh',
                                    'width': '100%',
                                    '& .actions': {
                                        color: 'text.secondary',
                                    },
                                    '& .textPrimary': {
                                        color: 'text.primary',
                                    },
                                }}
                            >
                                <Table headers={headCells} data={processedItems} setSelectedRow={() => { }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    );
}