import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';
import ReportsView from './views/reports_view';

export default function Orders() {
  return (
    <Routes>
      <Route path="/" element={<ReportsView />} />
      <Route path='*' element={<ContentNotFound />} />
    </Routes>
  );
}
