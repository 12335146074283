import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { TuiAppBar, TuiSpinner, Hooks, ComingSoon } from '../../../core';
import BillingsSettings from '../components/settings_billing';
import { useUpdateSettingsMutation } from '../../../js/slices/api_slices/settings_api_slice';
import Footer from '../components/footer';

export default function SettingsBillingView() {
  const [updateSettings, { isLoading, isSuccess, isError, error }] = useUpdateSettingsMutation();
  const settings = useSelector((store) => store?.settings.settings);
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const methods = useForm({
    defaultValues: {
      ...settings,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      successSnackBar({ message: 'Billing information Successfully Updated' });
    }
    if (isError) {
      errorSnackBar({ message: error?.data?.errorMessage || 'Billing information Failed to  Update' });
    }
  }, [isSuccess, isError]);

  if (isLoading) {
    return <TuiSpinner />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TuiAppBar
          title="Billing"
        />
      </Grid>
      <ComingSoon />
      {/* <FormProvider {...methods}>
        <Grid
          item
          xs={12}
          container>
          <Grid item container sx={{pt: 3, pl: 1}}>
            <Grid item xs={12} container sx={{pt: 3}}>
              <BillingsSettings settings={settings}/>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider> */}
      {/* <Footer
        buttonClickHandler={() => {
          updateSettings(methods.getValues());
        }}
        showButtonIcon={false}
        buttonText='Update'
        buttonDisabled={isLoading || !methods?.formState?.isDirty}
      /> */}
    </Grid>
  );
}
