import React, { useEffect, useState } from 'react';
import { useFieldArray, Controller, useForm } from 'react-hook-form';
import {
  Typography,
  Grid2 as Grid,
  Box,
  Modal,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormHelperText
} from '@mui/material';
import {
  DataGrid,
} from '@mui/x-data-grid';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { CoreButton, Hooks, TuiAutoComplete, BaseTextField, ToggleDays } from '../../../core/index';
import { DAYS, UNITS } from '../../../js/lib/constants';

import { tiffinsApi } from '../../../js/slices/api_slices';
const { useSnackBarNotification, useDebounce } = Hooks;
const { useGetTiffinItemsQuery, useCreateTiffinItemMutation } = tiffinsApi;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '1px solid',
  borderRadius: '5px',
  p: 2,
};

export default function CustomerMealPlanGoodsForm({ setValue, control, action, reset }) {
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'tiffin.items',
  });

  const [addNewTiffinItem] = useCreateTiffinItemMutation();
  const [open, setOpen] = useState(false);

  const [editIndex, setEditIndex] = useState(null);
  const [createItem, setCreateItem] = useState(false);

  const [inputItemName, setInputItemName] = useState('');
  const debouncedTiffinInput = useDebounce(inputItemName, 250);
  const { successSnackBar } = useSnackBarNotification();

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const { data: tiffinItems,
    isSuccess: tiffinItemFetchSuccess,
    isLoading: tiffinItemFetchLoading } = useGetTiffinItemsQuery({
      page: 1,
      pageSize: 20,
      contains: { name: debouncedTiffinInput },
    }, { refetchOnMountOrArgChange: true, skip: debouncedTiffinInput ? false : true });

  useEffect(() => {
    if (tiffinItemFetchSuccess && !tiffinItems?.data?.items?.length) {
      setCreateItem(true);
    }
  }, [tiffinItemFetchSuccess]);

  const deleteItemHandler = (id) => {
    const itemIndex = fields.findIndex((item) => item.id === id);
    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  // Initialize useForm for the nested form
  const {
    handleSubmit: handleNestedSubmit,
    control: nestedControl,
    watch: nestedWatch,
    setValue: setNestedValue,
    reset: nestedReset,
    register: nestedRegister,
    formState: { errors: nestedErrors },
  } = useForm({
    defaultValues: {
      name: '',
      quantity: '',
      unit: '',
      days: [1, 2, 3, 4, 5, 6, 7],
    },
  });

  const resetFields = () => {
    nestedReset({
      name: '',
      quantity: '',
      unit: '',
      days: [1, 2, 3, 4, 5, 6, 7],
    });
    setInputItemName('');
    setEditIndex(null);
  }


  const columns = [
    {
      field: 'name',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Name',
      width: 180,
      editable: false,
    },
    {
      field: 'quantity',
      align: 'center',
      headerAlign: 'left',
      headerName: 'Quantity',
      width: 90,
      editable: false,
    },
    {
      field: 'unit',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Unit',
      width: 120,
      editable: false,
    },
    {
      field: 'days',
      headerName: 'Days',
      width: 220,
      editable: false,
      type: 'multiselect',
      valueOptions: DAYS.map(({ shortName }) => shortName),
    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Actions',
      width: 130,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <IconButton
            key={`edit-${id}`}
            edge="end"
            aria-label="edit"
            onClick={() => {
              const item = fields.find((item) => item.id === id);
              setInputItemName(item?.name);
              nestedReset({
                name: item?.name,
                quantity: item?.quantity,
                unit: item?.unit,
                days: item?.days,
              });
              setEditIndex(fields.findIndex((item) => item.id === id));
              handleOpen();
            }
            }>
            <EditOutlinedIcon />
          </IconButton>,
          <IconButton
            key={`delete-${id}`}
            edge="end"
            aria-label="delete"
            onClick={() => deleteItemHandler(id)}
          >
            <DeleteOutlineIcon />
          </IconButton>,
        ];
      },
    },
  ];

  const onSubmitNestedForm = (data, e) => {
    if (editIndex !== null) {
      update(editIndex, data);
      successSnackBar({ message: 'Item updated successfully' });
    } else {
      append(data);
      successSnackBar({ message: 'Item added successfully' });
    }
    if (createItem) {
      addNewTiffinItem(data);
    }
    resetFields();
    setValue('itemsChanged', true);
  };


  const formModal = <Modal
    open={open}
    keepMounted
    aria-labelledby="modal-modal-item-form"
  >
    <Box sx={style}>
      <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
        {editIndex !== null ? 'Edit' : 'Add'} item
      </Typography>
      <Grid component='form' container size={12} onSubmit={(e) => {
        e.stopPropagation();
        handleNestedSubmit(onSubmitNestedForm)(e);
      }}>
        <Grid container spacing={2} size={12}>
          <Grid size={12} container spacing={1} sx={{ mt: 1 }}>
            <Grid size={5}>
              <Controller
                control={nestedControl}
                name="name"
                render={({ field }) => (
                  <TuiAutoComplete
                    id="name"
                    name="name"
                    freeSolo
                    errors={nestedErrors}
                    onChange={(event, item) => {
                      if (item) {
                        setNestedValue('quantity', item?.quantity);
                        setNestedValue('unit', UNITS[item?.unit]);
                      }
                      field.onChange(item?.name);
                    }}
                    inputValue={inputItemName}
                    onInputChange={(event, newInputValue) => {
                      setInputItemName(newInputValue);
                      field.onChange(newInputValue);
                    }}
                    labelKey='label'
                    helperText="e.g Rice"
                    label="Name"
                    options={tiffinItems?.data?.items.map(({ name, quantity, unit }) => ({
                      name,
                      quantity,
                      unit,
                      label: `${name}-${quantity}-${unit}`,
                    }))}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='quantity'
                label="Quantity"
                type="number"
                helperText="e.g 1"
                validate={nestedRegister('quantity', {
                  required: 'Quantity is required',
                  valueAsNumber: true,
                },
                )}
              />
            </Grid>
            <Grid size={4}>
              <Controller
                control={nestedControl}
                name="unit"
                render={({ field }) => (
                  <FormControl
                    required={true}
                    fullWidth
                  >
                    <InputLabel
                      sx={{
                        color: nestedErrors?.['unit']?.message ? 'secondary.contrastText.100' : 'default',
                      }}>
                      Unit
                    </InputLabel>
                    <Select
                      {...field}
                      id="unit"
                      error={!!nestedErrors?.['unit']?.message}
                      label="Unit"
                      onChange={(event) => field.onChange(event.target.value)}
                      value={field?.value}
                    >
                      {Object.values(UNITS).map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </Select>
                    <Typography fontSize={12} mt={1} ml={2} color='secondary.contrastText.100'>{nestedErrors?.type?.message}</Typography>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Grid size={12}>
            <Controller
              rules={{ required: 'Select valid delivery days' }}
              control={nestedControl}
              name="days"
              render={({ field }) => (
                <FormControl fullWidth error={!!nestedErrors?.deliveryDays?.message}>
                  <Grid container>
                    <Grid size={6}>
                      <FormLabel component="legend">Delivery Days</FormLabel>
                    </Grid>
                    <Grid size={6} container justifyContent='flex-end'>
                      <ToggleDays field={field} existingDays={field?.value || [1, 2, 3, 4, 5, 6, 7]} />
                      <FormHelperText>{nestedErrors?.deliveryDays?.message}</FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12} justifyContent='flex-end' container spacing={1}>
            <Grid size="auto">
              <CoreButton
                variant='outlined'
                fullWidth={false}
                isLoading={tiffinItemFetchLoading}
                onClickHandler={handleClose}
                startIcon={<CloseOutlinedIcon />}
              >Close
              </CoreButton>
            </Grid>
            <Grid size="auto">
              <CoreButton
                variant='contained'
                fullWidth={false}
                isLoading={tiffinItemFetchLoading}
                type="submit"
                disabled={!nestedWatch('name') || !nestedWatch('quantity') || !nestedWatch('unit') || !nestedWatch('days')}
                startIcon={<SaveOutlinedIcon/>}
              >Save
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Modal>;

  return (
    <>
      <Grid
        container
        size={12}
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          border: '1px solid #E0E0E0',
          height: '100%',
        }}>
        <Grid
          container
          spacing={1}
          sx={{
            p: 2
          }}>
          <Grid container size={12} sx={{ mt: 1 }} spacing={1}>
            <Grid container size={12}>
              <Grid size={6}>
                <Typography color="secondary.contrastText.main">List of the goods</Typography>
              </Grid>
              <Grid item size={6} justifyContent='flex-end'>
                <Grid size="auto" container justifyContent='flex-end' sx={{
                  height: 30,
                }}>
                  <CoreButton
                    startIcon={<AddOutlinedIcon />}
                    variant='contained'
                    fullWidth={false}
                    onClickHandler={() => {
                      resetFields();
                      handleOpen();
                    }}
                  >Add Item
                  </CoreButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={12}>
              <Box
                sx={{
                  'height': '85vh',
                  'width': '100%',
                  '& .actions': {
                    color: 'text.secondary',
                  },
                  '& .textPrimary': {
                    color: 'text.primary',
                  },
                }}
              >
                <DataGrid
                  disableSelectionOnClick={true}
                  rows={fields?.map((item) => ({
                    ...item,
                    days: item?.days.map((day) => DAYS.find(({ value }) => value === day).shortName),
                  }))}
                  columns={columns}
                  editMode="row"
                  processRowUpdate={() => { }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {formModal}
    </>
  );
}
