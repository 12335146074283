import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TuiAppBar, Hooks, BasePagination, DataNotFound, TuiSpinner } from '../../../core';
import { employeesApi } from '../../../js/slices/api_slices';
import ListComponent from '../components/employees_list_component';
import { USERS, STORAGE_KEYS } from '../../../js/lib/constants';

const { useGetEmployeesQuery } = employeesApi;
const { useResponsivePageSize, usePageNumberAndSearch, useDebounce } = Hooks;

const userTypes = [
  USERS.TYPES.EMPLOYEE.COOK,
  USERS.TYPES.EMPLOYEE.DRIVER,
  USERS.TYPES.EMPLOYEE.PACKER,
  USERS.TYPES.EMPLOYEE.MANAGER,
];

export default function EmployeesView() {
  const [pageSize, setPageSize] = useResponsivePageSize(localStorage.getItem(STORAGE_KEYS.EMPLOYEE_PAGINATION) ?
  JSON.parse(localStorage.getItem(STORAGE_KEYS.EMPLOYEE_PAGINATION))?.pageSize : 10);
  const roles = useSelector((store) => store?.roles?.roles);
  const settings = useSelector((store) => store?.settings?.settings);
  const [items, setItems] = useState([]);

  const navigate = useNavigate();
  const [page, setPage, searchText, setSearchText] = usePageNumberAndSearch();

  const debouncedSearchTerm = useDebounce(searchText, 500);

  const { data, isLoading, isSuccess } = useGetEmployeesQuery({
    page,
    pageSize,
    contains: { 'firstName': debouncedSearchTerm, 'lastName': debouncedSearchTerm, 'address.description': debouncedSearchTerm },
    sortKey: '+status',
    type: userTypes
  }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccess && data?.data?.items) {
      setItems(data?.data?.items);
    }
  }, [isSuccess, data]);

  if (isLoading) {
    return <TuiSpinner />;
  }

  return (
    <>
      <Grid container spacing={1} sx={{ height: data?.data?.items?.length <= 0 ? '100%' : null }}>
        <Grid item xs={12} md={12} lg={12}>
          <TuiAppBar
            buttonDisabled={!roles?.employees?.create}
            buttonClickHandler={() => {
              navigate('new');
            }}
            setSearchText={setSearchText}
            searchText={searchText}
            searchHelperText='Address/Name'
            title='Employees'
          />
        </Grid>
        <Grid item xs={12}>
          {!data?.data?.items?.length && <DataNotFound title="employees" />}
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }}>
          {data?.data?.items?.length > 0 && <ListComponent
            isLoading={isLoading}
            items={items}
            setItems={setItems}
            setPage={setPage}
            page={page}
          />}
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end' alignItems="flex-end" sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <BasePagination
          data={data}
          pageSize={pageSize}
          page={page - 1}
          pageSizeChangeHandler={(event) => {
            setPageSize(parseInt(event.target.value));
            setPage(1);
          }}
          pageChangeHandler={(event, val) => {
            setPage(val + 1);
          }}
          localStorageKey={STORAGE_KEYS.EMPLOYEE_PAGINATION}
        />
      </Grid>
    </>
  );
}
