import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function usePageNumberAndSearch(defaultPage = 1, defaultSearchText = '') {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse query parameters to get the page number and search text
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page'), 10) || defaultPage;
  const initialSearchText = queryParams.get('searchText') || defaultSearchText;

  // Get the page number and search text from the location state or default to the provided defaults
  const [page, setPage] = useState(initialPage);
  const [searchText, setSearchText] = useState(initialSearchText);

  // Update the location state whenever the page or search text changes or when the component is first loaded
  useEffect(() => {
    if (!queryParams.has('page')) {
      // Add page=1 to the URL if it does not exist or if query contains "searchText"
      navigate(`${location.pathname}?page=${defaultPage}${searchText ? `&searchText=${searchText}` : ''}`, { replace: true });
    } else if (initialPage !== page || initialSearchText !== searchText) {
      navigate(`${location.pathname}?page=${page}${searchText ? `&searchText=${searchText}` : ''}`, { state: { page, searchText } });
    }
  }, [page, searchText, navigate, location.pathname, initialPage, initialSearchText, queryParams, defaultPage]);

  // Reset page to 1 when search text changes
  useEffect(() => {
    setPage(defaultPage);
  }, [searchText, defaultPage]);

  return [page, setPage, searchText, setSearchText];
}

export default usePageNumberAndSearch;