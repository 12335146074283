import {createApi} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat} from '../../actions/action_helper';

const googleMapsApi = createApi({
  reducerPath: 'googleMapsApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getPlaces: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `app/places?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getPlaceById: builder.query({
      query: ({id}) => ({
        ...generateRequestOptions({
          url: `/app/places/${id}`,
          method: 'GET',
        }),
      }),
    }),
  }),
});

export const {
  useGetPlacesQuery,
  useGetPlaceByIdQuery,
  useLazyGetPlacesQuery,
  useLazyGetPlaceByIdQuery,
} = googleMapsApi;

export default googleMapsApi;
