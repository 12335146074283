import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Typography} from '@mui/material';
import {Container} from '@mui/system';

import CoreButton from './button';
import ComingSoon from '../assets/images/coming_soon.svg';

export default function ComingSoonView() {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <img src={ComingSoon} alt="coming-soon" width="300" height="300"/>
      <Typography variant='h5' fontWeight='bold'>Coming Soon</Typography>
      <Typography maxWidth="30%" fontSize={15} color="secondary.contrastText.main">The content you are looking for is being constructed, we will notify you once it is ready</Typography>
      <CoreButton
        variant='contained'
        fullWidth={false}
        onClickHandler={() => {
          navigate(-1, {replace: true});
        }}
        sx={{
          'width': '200px',
          'fontSize': '15',
          'mt': 2,
          'backgroundColor': 'secondary.contrastText',
          ':hover': {
            backgroundColor: 'secondary.contrastText',
          },
        }}
      >Go Back
      </CoreButton>
    </Container>
  );
}
