import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {Grid, Typography, Link} from '@mui/material';

import {CoreButton, BaseTextField, PublicContainer, Hooks} from '../../../core';
import {useForgotPasswordMutation} from '../../../js/slices/api_slices/auth_api_slice';

const {useSnackBarNotification} = Hooks;

export default function PasswordRecoverySetNewComponent() {
  const {register, handleSubmit, watch, formState: {errors}} = useForm();
  const navigate = useNavigate();
  const {errorSnackBar} = useSnackBarNotification();
  const location = useLocation();
  const [forgotPasswordMutation, {
    data,
    isSuccess,
    isError,
    error,
    isLoading,
  }] = useForgotPasswordMutation();

  useEffect(() => {
    if (isSuccess && data) {
      navigate('/reset-password/success', {replace: true});
    } if (isError && error) {
      errorSnackBar({message: error?.data?.errorMessage});
    }
  }, [data, isSuccess, error, isError]);

  const component = <Grid container justifyContent='center'>
    <Grid
      item
      container
      component="form"
      onSubmit={handleSubmit(({newPassword, confirmNewPassword}) => {
        const {code, email} = location.state;
        forgotPasswordMutation({code, password: newPassword, confirmPassword: confirmNewPassword, email});
      })}
      sx={{maxWidth: 400, p: 1}}
      spacing={2}
    >
      <Grid item xs={12} container justifyContent='center'>
        <Typography variant="h5" fontWeight="bold" >
        Set New Password
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent='center'>
        <Typography mb={4} color="secondary.contrastText.main">
        Create New Password
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BaseTextField
          id="newPassword"
          name="newPassword"
          label="New Password"
          type="password"
          errors={errors}
          validate={register('newPassword', {
            required: 'New Password is required',
            minLength: {
              value: 6,
              message: 'Password must be greater then 6 characters',
            },
          })}
          sx={{
            mt: 5,
          }}

        />
      </Grid>
      <Grid item xs={12}>

        <BaseTextField
          id='confirmNewPassword'
          name="confirmNewPassword"
          label="Cofirm New Password"
          type="password"
          errors={errors}
          validate={register('confirmNewPassword', {
            required: 'Confirm New Password',
            validate: (value) => {
              if (watch('newPassword') !== value) {
                return 'You password does not match';
              }
            },
          })}
        />
      </Grid>
      <Grid item xs={12}>

        <CoreButton
          isLoading={isLoading}
          type='submit'
          variant='contained'
          color="primary"
        >
        Verify
        </CoreButton>
      </Grid>
      <Grid
        item
        xs={12}
        container
        sx={{pt: 1}}
      >
        <Typography fontSize={15}>
          <Link href='/login'>Back to Login</Link>
        </Typography>
      </Grid>
    </Grid>
  </Grid>;

  return (
    <PublicContainer component={component} />
  );
}
