import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid2 as Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import LeadForm from '../components/lead_form';
import { leadsApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, CoreButton, TuiSpinner, Footer } from '../../../core/index';

const { useSnackBarNotification } = Hooks;
const { useUpdateLeadMutation, useGetLeadQuery } = leadsApi;

export default function EditLeadView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const { data: fetchedData, isLoading: itemLoading } = useGetLeadQuery({
    id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const methods = useForm({
    defaultValues: {
      ...fetchedData?.data,
      date: fetchedData?.data?.date ? moment(fetchedData?.data?.date) : null,
    },
    mode: 'onChange',
  });

  const { handleSubmit, reset, formState: { isDirty } } = methods;

  useEffect(() => {
    reset({
      ...fetchedData?.data,
      date: fetchedData?.data?.date ? moment(fetchedData?.data?.date) : moment(),
    });
  }, [fetchedData]);

  const [updateLead, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useUpdateLeadMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Lead updated successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
  }, [isSuccess, isError, data, error]);

  if (itemLoading) {
    return <TuiSpinner />;
  }

  return (
    <Grid
      container
      component='form'
      onSubmit={handleSubmit((userInput) => {
        userInput.startDate = moment(userInput?.startDate).valueOf();
        updateLead(userInput);
      })}>
      <Grid size={12} container>
        <TuiAppBar title='Edit Lead' />
      </Grid>
      <Grid
        size={12}
        justifyContent='center'
        container
      >
        <FormProvider {...methods}>
          <LeadForm />
        </FormProvider>
      </Grid>
      <Footer>
        <Grid container spacing={1} size={12} justifyContent='flex-end' sx={{ p: 1 }}>
          <Grid size='auto'>
            <CoreButton
              fullWidth={false}
              onClickHandler={() => navigate(-1)}
              variant="outlined"
              startIcon={<ArrowBackIosNewOutlinedIcon />}
            >
              Back
            </CoreButton>
          </Grid>
          <Grid size='auto'>
            <CoreButton
              isLoading={isLoading}
              type='submit'
              fullWidth={false}
              variant="contained"
              disabled={!isDirty}
              startIcon={<SaveOutlinedIcon />}
            >
              Save
            </CoreButton>
          </Grid>
        </Grid>
      </Footer>
    </Grid>
  );
}
