import { useEffect } from 'react';

function ussLocalStoragePersist({
    value,
    localStorageKey,
}) {
    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [value, localStorageKey]);

    return;
}

export default ussLocalStoragePersist;
