import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Alert, Grid2 as Grid, Tooltip } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RouteForm from '../components/route_form';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { deliveryRoutesApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, CoreButton, Footer } from '../../../core/index';
import { ROUTE, STORAGE_KEYS } from '../../../js/lib/constants';
import RouteAreaDrawer from '../components/route_area_drawer';

const { useSnackBarNotification, useSessionStoragePersist } = Hooks;
const { useCreateDeliveryRouteMutation } = deliveryRoutesApi;

export default function CreateRouteView() {
  const navigate = useNavigate();
  const [showRouteAreaDrawer, setShowRouteAreaDrawer] = useState(false);
  const settings = useSelector((store) => store?.settings?.settings);
  let initialData = sessionStorage.getItem(STORAGE_KEYS.ROUTE);
  if (initialData) {
    try {
      let initialJsonData = JSON.parse(initialData);
      initialJsonData.startTime = moment(initialJsonData?.startTime);
      initialData = initialJsonData;
    } catch (err) {
      console.error('Error parsing initialData', err);
    }
  } else {
    initialData = {
      startTime: moment().set({ h: 17, m: 0, s: 0 }),
      startAddress: settings?.address,
      finishAddress: null,
      deliveryArea: {
        type: null,
        coordinates: [],
      }
    }
  }

  const methods = useForm({
    defaultValues: initialData,
    mode: 'onChange',
  });

  const { handleSubmit, watch } = methods;

  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [addNewRoute, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useCreateDeliveryRouteMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Route created successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
    return () => {
      if (isSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.ROUTE);
      }
    }
  }, [isSuccess, isError, data, error]);

  useSessionStoragePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.ROUTE });

  return (
    <Grid
      container
      component='form'
      onSubmit={handleSubmit((userInput) => {
        userInput.startTime = moment(userInput?.startTime).valueOf();
        addNewRoute(userInput);
      })}
    >
      <Grid container size={12} spacing={1}>
        <Grid size={12}>
          <TuiAppBar title='New Route' />
        </Grid>
        <Grid
          sx={{ mt: 1 }}
          container
          size={12}
        >
          <FormProvider {...methods}>
            <Grid size={12} container sx={{ p: 1, height: 'inherit' }}>
              <RouteForm
                viewOnly={false}
                item={null}
              />
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
      <Footer>
        <Grid container size={12} justifyContent='flex-end' spacing={1} sx={{ p: 1 }}>
          <Grid>
            <CoreButton
              startIcon={<ArrowBackIosNewOutlinedIcon />}
              fullWidth={false}
              variant='outlined'
              onClick={() => navigate(-1)}
            >
              Back
            </CoreButton>
          </Grid>
          <Grid>
            <CoreButton
              startIcon={<SaveOutlinedIcon />}
              type='submit'
              fullWidth={false}
              variant='contained'
              isLoading={isLoading}
            >
              Save
            </CoreButton>
          </Grid>
        </Grid >
      </Footer>
    </Grid>
  );
}
