import React, { useRef } from 'react';
import { Typography, Grid, Badge, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import BaseTextField from './base_text_field';
import CoreButton from './button';

export default function AppBar({
  title,
  searchHelperText,
  setSearchText,
  selectedItems = [],
  buttonClickHandler,
  buttonDisabled,
  selectedItemsMenu,
  searchText = '',
  menuItems,
  filter,
  exportData = null
}) {
  const theme = useTheme();
  const searchInputRef = useRef(null);

  return (<Grid container justifyContent='space-between'>
    <Grid
      item
      xs='auto'
      sx={{ mt: 1 }}>
      {
        typeof title === 'string' ? (
          <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
            {title}
          </Typography>
        ) : React.isValidElement(title) ? (
          title
        ) : null
      }
    </Grid>
    <Grid item container justifyContent='flex-end' xs='auto' spacing={1}>
      {!selectedItems?.length && <>
        {menuItems && <Grid item xs='auto'>
          {menuItems}
        </Grid>}
        {exportData && exportData.exportHandler && <Grid item xs='auto'>
          <IconButton
              sx={{
                mt: 1.2,
                p: 0.6,
                border: '1px solid', // Add border
                borderColor: 'grey.500', // Set border color
                borderRadius: '10%', // Make the border rounded
              }}
              onClick={() => exportData.exportHandler()}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
        </Grid>}
        {filter && <Grid item xs='auto'>
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            color="error"
            badgeContent={filter.filterCount}
            max={999}>
            <IconButton
              sx={{
                mt: 1.2,
                p: 0.6,
                border: '1px solid', // Add border
                borderColor: 'grey.500', // Set border color
                borderRadius: '10%', // Make the border rounded
              }}
              onClick={() => filter.setShowFilterModal(!filter.showFilterModal)}
            >
              <TuneOutlinedIcon />
            </IconButton>
          </Badge>
          {filter.filterModal}
        </Grid>}
        {setSearchText && <Grid item xs='auto' justifyContent='flex-end' sx={{ ml: 0.5 }}>
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            badgeContent={searchText ? 1 : 0}
            color="error"
            invisible={!searchText}
          >
            <BaseTextField
              sx={{
                mt: 1,
                width: '100%',
                '& .MuiInputBase-input': {
                  padding: theme.spacing(1, 1, 1, 0),
                  // vertical padding + font size from searchIcon
                  transition: theme.transitions.create('width'),
                  [theme.breakpoints.up('sm')]: {
                    width: '0ch',
                    '&:focus': {
                      width: '20ch',
                    },
                  },
                },
              }}
              value={searchText || ''}
              startAdornment={<SearchIcon onClick={() => searchInputRef?.current?.focus()} />}
              inputRef={searchInputRef}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              placeholder={searchHelperText}
            />
          </Badge>
        </Grid>}
        <Grid item sx={{ mt: 1 }} xs='auto'>
          {buttonClickHandler && typeof buttonClickHandler === 'function' &&
            <CoreButton
              variant="contained"
              disabled={buttonDisabled}
              onClickHandler={buttonClickHandler}
              startIcon={<AddOutlinedIcon />}
            > Add
            </CoreButton>}
        </Grid>
      </>}
      <Grid item xs='auto'>
        {selectedItems?.length > 0 && selectedItemsMenu}
      </Grid>
    </Grid>
  </Grid>);
}

AppBar.propTypes = {
  searchText: PropTypes.string,
};

