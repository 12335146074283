import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Typography, Card} from '@mui/material';

import {CoreButton, PublicContainer} from '../../../core';

export default function PasswordRecoverySuccessComponent() {
  const navigate = useNavigate();
  const component = <Container component="main" sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    width: '100vw',
  }}>
    <Container disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        raised
        sx={{
          marginTop: 12,
          width: '616px',
          height: '312px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h4" fontWeight='bold' mt={4}>
          Password Recovery
        </Typography>
        <Typography fontSize={15} color="secondary.contrastText.main" mb={12}>
          Your password has been successfully reset
        </Typography>
        <CoreButton
          variant='contained'
          fullWidth={false}
          color="primary"
          sx={{
            'width': '50%',
            ':hover': {
              backgroundColor: 'primary.light',
            },
          }}
          onClickHandler={() => navigate('/login', {replace: true})}
        >
          Go To login
        </CoreButton>
      </Card>
    </Container>
  </Container>;

  return (
    <PublicContainer component={component} />
  );
}
