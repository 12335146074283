import { createApi } from '@reduxjs/toolkit/query/react';
import { generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat } from '../../actions/action_helper';

const expenseApi = createApi({
    reducerPath: 'Expenses Api',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getExpenses: builder.query({
            query: (args) => ({
                ...generateRequestOptions({
                    url: `/app/expenses?${objectToQueryFormat(args)}`,
                    method: 'GET',
                }),
            }),
        }),
        getExpense: builder.query({
            query: (args) => ({
                ...generateRequestOptions({
                    url: `/app/expenses/${args.id}`,
                    method: 'GET',
                }),
            }),
        }),
        createExpense: builder.mutation({
            query: (data) => ({
                ...generateRequestOptions({
                    url: '/app/expenses',
                    method: 'POST',
                    data,
                }),
            }),
        }),
        updateExpense: builder.mutation({
            query: (data) => ({
                ...generateRequestOptions({
                    url: `/app/expenses/${data?.id}`,
                    method: 'PUT',
                    data,
                }),
            }),
        }),
        deleteExpense: builder.mutation({
            query: (data) => ({
                ...generateRequestOptions({
                    url: `/app/expenses/${data?.id}`,
                    method: 'DELETE',
                }),
            }),
        }),
        bulkOperation: builder.mutation({
            query: (data)  => ({
                ...generateRequestOptions({
                    url: `/app/expenses/bulk`,
                    method: 'POST',
                    data,
                }),
            }),
        }),
    }),
});

export const {
    useGetExpensesQuery,
    useGetExpenseQuery,
    useCreateExpenseMutation,
    useUpdateExpenseMutation,
    useDeleteExpenseMutation,
    useBulkOperationMutation,
} = expenseApi;

export default expenseApi;
