import { useState, useEffect } from 'react';

export default function useResponsivePageSize(optionalPageSize) {
  const [pageSize, setPageSize] = useState(optionalPageSize || 10);

  useEffect(() => {
    if (!optionalPageSize) {
      setPageSize(10);
    }
  }, [optionalPageSize]);

  return [pageSize, setPageSize];
}