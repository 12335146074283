import React from 'react';
import { Chip } from '@mui/material';
import { TIFFIN } from '../../js/lib/constants';

export const getRouteStatusChip = (status) => {
  switch (status) {
    case TIFFIN.DELIVERY_ROUTES_STATUS.NOT_READY:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STATUS.NOT_READY} color="info" />;
    case TIFFIN.DELIVERY_ROUTES_STATUS.READY:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STATUS.READY} color="info" />;
    case TIFFIN.DELIVERY_ROUTES_STATUS.IN_PROGRESS:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STATUS.IN_PROGRESS} sx={{ backgroundColor: '#EE8D22', color: 'white' }} />;
    case TIFFIN.DELIVERY_ROUTES_STATUS.COMPLETED:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STATUS.COMPLETED} sx={{ backgroundColor: '#238443', color: 'white' }} />;
    case TIFFIN.DELIVERY_ROUTES_STATUS.CANCELLED:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STATUS.CANCELLED} sx={{ backgroundColor: '#818181', color: 'white' }} />;
  }
};

export const getStopStatusChip = (status) => {
  switch (status) {
    case TIFFIN.DELIVERY_ROUTES_STOP_STATUS.PENDING:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STOP_STATUS.PENDING} color="info" />;
    case TIFFIN.DELIVERY_ROUTES_STOP_STATUS.ON_THE_WAY:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STOP_STATUS.ON_THE_WAY} sx={{ backgroundColor: 'orange', color: 'white' }} />;
    case TIFFIN.DELIVERY_ROUTES_STOP_STATUS.DELIVERED:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STOP_STATUS.DELIVERED} sx={{ backgroundColor: 'green', color: 'white' }} />;
    case TIFFIN.DELIVERY_ROUTES_STOP_STATUS.FAILED:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STOP_STATUS.FAILED} sx={{ backgroundColor: 'red', color: 'white' }} />;
    case TIFFIN.DELIVERY_ROUTES_STOP_STATUS.CANCELLED:
      return <Chip label={TIFFIN.DELIVERY_ROUTES_STOP_STATUS.CANCELLED} sx={{ backgroundColor: 'grey', color: 'white' }} />;
  }
}