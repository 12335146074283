import { createApi } from '@reduxjs/toolkit/query/react';
import { generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat } from '../../actions/action_helper';

const deliveryRoutesApi = createApi({
  reducerPath: 'Delivery Routes Api',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getDeliveryRoutes: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/delivery_routes?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getRecommendedDeliveryRoutes: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/delivery_routes/recommended?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getDeliveryRoute: builder.query({
      query: ({ id }) => ({
        ...generateRequestOptions({
          url: `/app/delivery_routes/${id}`,
          method: 'GET',
        }),
      }),
    }),
    createDeliveryRoute: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: '/app/delivery_routes',
          method: 'POST',
          data,
        }),
      }),
    }),
    updateDeliveryRoute: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: `/app/delivery_routes/${data?.id}`,
          method: 'PUT',
          data,
        }),
      }),
    }),
    deleteDeliveryRoute: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: `/app/delivery_routes/${data?.id}`,
          method: 'DELETE',
        }),
      }),
    }),
  }),
});

export const {
  useGetDeliveryRoutesQuery,
  useLazyGetDeliveryRoutesQuery,
  useGetRecommendedDeliveryRoutesQuery,
  useGetDeliveryRouteQuery,
  useCreateDeliveryRouteMutation,
  useUpdateDeliveryRouteMutation,
  useDeleteDeliveryRouteMutation
} = deliveryRoutesApi;

export default deliveryRoutesApi;
