import { createApi } from '@reduxjs/toolkit/query/react';
import { generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat } from '../../actions/action_helper';

const historyApi = createApi({
  reducerPath: 'History Api',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getHistory: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/history?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getSingleHistory: builder.query({
      query: ({ id, routeId, stopId }) => {
        return {
          ...generateRequestOptions({
            url: `/app/history/${id}?routeId=${routeId}&stopId=${stopId}`,
            method: 'GET',
          }),
        };
      }
    }),
  }),
});

export const {
  useGetHistoryQuery,
  useGetSingleHistoryQuery,
} = historyApi;

export default historyApi;
