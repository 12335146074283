import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';

import { TuiAppBar, TuiSpinner } from '../../../core';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { getStatusChip } from '../reports_utils';
import { TIFFIN } from '../../../js/lib/constants';
const { useGetTiffinSubscriberStatsQuery } = tiffinsApi;

export default function TiffinReportsView() {
  const {
    data,
    isLoading,
  } = useGetTiffinSubscriberStatsQuery({}, { refetchOnMountOrArgChange: true });

  if (isLoading) return <TuiSpinner />;
  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <TuiAppBar
          title="Reports"
        />
      </Grid>
      <Grid item xs={12} container sx={{ mt: 4 }}>
        {data && <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item xs={3}>
              {getStatusChip({ status: TIFFIN.STATUS.ACTIVE })}
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6'>{data?.data?.activeCount}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item xs={3}>
              {getStatusChip({ status: TIFFIN.STATUS.EXPIRING_SOON })}
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6'>{data?.data?.expiringSoonCount}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item xs={3}>
              {getStatusChip({ status: TIFFIN.STATUS.EXPIRED })}
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6'>{data?.data?.expiredCount}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item xs={3}>
              {getStatusChip({ status: TIFFIN.STATUS.HOLD })}
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6'>{data?.data?.holdCount}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item xs={3}>
              {getStatusChip({ status: TIFFIN.STATUS.CANCEL })}
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6'>{data?.data?.cancelledCount}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item xs={3}>
              {getStatusChip({ status: TIFFIN.STATUS.NOT_STARTED })}
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6'>{data?.data?.notStartedCount}</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ width: '100%', pt: 4 }} />
          <Grid item xs={12} container justifyContent='center'>
            <Grid item xs={3}>
              <Typography variant='h5'>Total Tiffins</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6'>{data?.data?.activeCount +
                data?.data?.expiringSoonCount +
                data?.data?.expiredCount +
                data?.data?.cancelledCount +
                data?.data?.notStartedCount +
                data?.data?.holdCount}</Typography>
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </Grid>
  );
}
