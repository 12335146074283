import React from 'react';
import { Chip } from '@mui/material';
import { USERS } from '../../js/lib/constants';
import Badge from '@mui/material/Badge';
import DriveEtaIcon from '@mui/icons-material/DriveEta'; // For drivers
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'; // For managers

export const getStatusChip = (status) => {
    switch (status) {
        case USERS.STATUS.ACTIVE:
            return <Chip label={USERS.STATUS.ACTIVE} sx={{ backgroundColor: '#238443', color: 'white' }} />;
        case USERS.STATUS.BLOCKED:
            return <Chip label={USERS.STATUS.BLOCKED} sx={{ backgroundColor: '#818181', color: 'white' }} />;
    }
};

export const getTypeChip = (type) => {
    switch (type) {
        case USERS.TYPES.EMPLOYEE.DRIVER:
            return <Badge badgeContent={<DriveEtaIcon />} color="primary">Driver</Badge>;
        case USERS.TYPES.MANAGER:
            return <Badge badgeContent={<SupervisorAccountIcon />} color="secondary">Manager</Badge>;
    }
};