import React from 'react';
import { Chip } from '@mui/material';
import { LEADS } from '../../js/lib/constants';

export const getStatusChip = (status) => {
    switch (status) {
        case LEADS.STATUS.NEW:
            return <Chip label={LEADS.STATUS.NEW} color='info' />;
        case LEADS.STATUS.ACCEPTED:
            return <Chip label={LEADS.STATUS.ACCEPTED} color='success' />;
        case LEADS.STATUS.REJECTED:
            return <Chip label={LEADS.STATUS.REJECTED} color='error' />;
    }
};