import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import RouteStopForm from '../components/route_stop_form';
import { stopsApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, TuiSpinner, CoreButton } from '../../../core/index';

const { useUpdateStopMutation, useGetStopQuery } = stopsApi;

export default function EditStopView() {
  const navigate = useNavigate();
  const { stopId } = useParams();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const { data: fetchedData, isLoading: itemLoading } = useGetStopQuery(stopId, { refetchOnMountOrArgChange: true, skip: !stopId });

  const { register, handleSubmit, formState: { errors }, reset, control, getValues, setValue, watch } = useForm({
    defaultValues: {
      ...fetchedData?.data,
    },
  });

  const [updateStop, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useUpdateStopMutation();

  useEffect(() => {
    reset(fetchedData?.data);
  }, [fetchedData?.data]);

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Stop modified successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
  }, [isSuccess, isError, data, error]);


  if (itemLoading) return <TuiSpinner />;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TuiAppBar title="Edit Stop" />
        </Grid>
        <Grid
          container
          sx={{ mt: 1 }}
          component='form'
          onSubmit={handleSubmit((userInput) => {
            userInput.id = stopId;
            updateStop(userInput);
          })}>
          <Grid item xs={12} container sx={{ p: 1 }}>
            <RouteStopForm
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              control={control}
              register={register}
              errors={errors}
              viewOnly={false}
              item={fetchedData?.data}
            />
          </Grid>
          <Grid item xs={12} container justifyContent='flex-end' spacing={2} alignContent='flex-end' sx={{ bottom: 40, position: 'fixed', right: 20, width: '100%' }}>
            <Grid item>
              <CoreButton
                isLoading={isLoading}
                fullWidth={true}
                onClickHandler={() => navigate(-1)}
                variant="contained"
              >
                Back
              </CoreButton>
            </Grid>
            <Grid item>
              <CoreButton
                isLoading={isLoading}
                type='submit'
                fullWidth={true}
                variant="contained"
              >
                Update
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
