import React from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { DAYS } from '../js/lib/constants';

const ToggleDays = ({ field, existingDays, disabled = false, customColor }) => {
  return (
    <ToggleButtonGroup
      onChange={(event, value) => field?.onChange(value)}
      value={field?.value || existingDays}
      sx={{
        '.MuiToggleButtonGroup-grouped': {
          'mr': 1,
          '&:not(:first-of-type)': {
            borderRadius: '50%',
            borderColor: customColor || 'secondary.main',
          },
          '&:first-of-type': {
            borderRadius: '50%',
            borderColor: customColor || 'secondary.main',
          },
        },
      }}
      arial-label="Days of the week"
    >
      {DAYS.map((day) => (
        <ToggleButton
          sx={{
            'height': 40,
            'width': 40,
            'textTransform': 'unset',
            'fontSize': '0.8rem',
            '&.Mui-selected, &.Mui-selected:hover': {
              color: 'white',
              backgroundColor: customColor || 'secondary.contrastText',
              borderColor: customColor || 'secondary.contrastText',
            },
            '&.Mui-selected:disabled': {
              color: 'white',
              backgroundColor: customColor || 'secondary.disabledBackground',
              borderColor: customColor || 'secondary.disabledBackground',
            },
          }}
          disabled={disabled}
          key={day.value}
          value={day.value}
          aria-label={day.key}>
          {day.shortName}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleDays;
