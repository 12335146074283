import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
    Grid2 as Grid,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    Tooltip,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { BaseTextField, DatePicker } from '../../../core/index';

export default function ExpenseForm({ action }) {
    const { register, control, formState: { errors } } = useFormContext();
    const navigate = useNavigate();

    return <Grid
        container
        size={12}
        justifyContent="center"
        sx={{
            height: '100%',
            p: 2,
        }}>
        <Grid size={6} container spacing={3} sx={{
            p: 4,
            mt: 1,
            backgroundColor: 'white',
            border: '1px solid #e0e0e0',
            borderColor: 'grey.300',
            borderRadius: '5px',
        }}>
            <Grid size={12}>
                <BaseTextField
                    id='description'
                    size="medium"
                    name="description"
                    label="Description"
                    errors={errors}
                    required={true}
                    validate={register('description', {
                        required: 'Description must be valid',
                        maxLength: {
                            value: 100,
                            message: 'Description must be less than 100 characters'
                        },
                    })}
                />
            </Grid>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <Controller
                        control={control}
                        rules={{ required: 'Invalid Date' }}
                        name="dateTime"
                        defaultValue={moment().format('YYYY-MM-DD')}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <DatePicker
                                    size="medium"
                                    id='dateTime'
                                    control={control}
                                    onChange={(event) => {
                                        onChange(event);
                                    }}
                                    required={true}
                                    errors={errors}
                                    label="Date"
                                    value={value ? moment(value) : null}
                                />
                            );
                        }
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <BaseTextField
                        id='category'
                        size="medium"
                        name="category"
                        label="Category"
                        errors={errors}
                        required={true}
                        validate={register('category', {
                            required: 'Category must be valid',
                        })}
                    />
                </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <BaseTextField
                        id='amount'
                        size="medium"
                        name="amount"
                        label="Amount"
                        errors={errors}
                        required={true}
                        startAdornment={'$'}
                        step="0.01" // Allows decimal values
                        validate={register('amount', {
                            required: 'Amount must be valid',
                            min: {
                                value: 0,
                                message: 'Amount must be greater than 0'
                            },
                            max: {
                                value: 1000000,
                                message: 'Amount must be less than 1000000'
                            },
                            pattern: {
                                value: /^\d+(\.\d{1,2})?$/, // Allows up to 2 decimal places
                                message: 'Amount must be a valid number with up to 2 decimal places'
                            }
                        })}
                    />
                </Grid>
                {action === 'new' && <Grid size={6} container spacing={1} sx={{
                    border: '1px solid #e0e0e0',
                    borderColor: 'grey.400',
                    borderRadius: '5px',
                }}>
                    <Grid size={6} container>
                        <Typography sx={{ p: 1, pr: 0 }}>Includes HST</Typography>
                        <Tooltip
                            arrow
                            placement="top"
                            title='HST will be calculated based on province'
                        >
                            <HelpIcon fontSize='small' color='secondary' sx={{ mt: 1.2 }} />
                        </Tooltip>
                    </Grid>
                    <Grid size={6}>
                        <Controller
                            rules={{ required: 'Invalid Type' }}
                            control={control}
                            name="hstIncluded"
                            render={({ field }) => {
                                return (
                                    <RadioGroup
                                        {...field}
                                        onChange={(event, value) => {
                                            field.onChange(value);
                                        }}
                                        row
                                        aria-labelledby="via-buttons-group-label"
                                        name="type"
                                    >
                                        {['Yes', 'No'].map((value) => {
                                            return <FormControlLabel
                                                key={value}
                                                value={value === 'Yes'}
                                                control={
                                                    <Radio sx={{
                                                        '&, &.Mui-checked': {
                                                            color: 'secondary.contrastText',
                                                        },
                                                    }} />
                                                }
                                                label={value} />
                                        })}
                                    </RadioGroup>
                                );
                            }}
                        />
                    </Grid>
                </Grid>}
                {
                    action === 'edit' && <Grid size={6}>
                        <BaseTextField
                            id='hstAmount'
                            size="medium"
                            name="hstAmount"
                            label="Hst Amount"
                            errors={errors}
                            required={true}
                            startAdornment={'$'}
                            step="0.01" // Allows decimal values
                            validate={register('hstAmount', {
                                required: 'Amount must be valid',
                                min: {
                                    value: 0,
                                    message: 'Amount must be greater than 0'
                                },
                                max: {
                                    value: 1000000,
                                    message: 'Amount must be less than 1000000'
                                },
                                pattern: {
                                    value: /^\d+(\.\d{1,2})?$/, // Allows up to 2 decimal places
                                    message: 'Amount must be a valid number with up to 2 decimal places'
                                }
                            })}
                        />
                    </Grid>
                }
            </Grid>
        </Grid>
    </Grid>
}
