import React from 'react';
import { Chip } from '@mui/material';
import { TIFFIN } from '../../js/lib/constants';

export const getStatusChip = (item) => {
  switch (item?.status) {
    case TIFFIN.STATUS.NOT_STARTED:
      return <Chip label={TIFFIN.STATUS.NOT_STARTED} sx={{ backgroundColor: "#2196f3", color: 'white' }} />;
    case TIFFIN.STATUS.ACTIVE:
      return <Chip label='Active' sx={{ backgroundColor: '#238443', color: 'white' }} />;
    case TIFFIN.STATUS.EXPIRING_SOON:
      return <Chip label='Expiring' sx={{ backgroundColor: '#EE8D22', color: 'white' }} />;
    case TIFFIN.STATUS.EXPIRED:
      return <Chip label='Expired' sx={{ backgroundColor: '#E31A1C', color: 'white' }} />;
    case TIFFIN.STATUS.HOLD:
      return <Chip label='Hold' sx={{ backgroundColor: '#008B8B', color: 'white' }} />;
    case TIFFIN.STATUS.CANCEL:
      return <Chip label='Cancel' sx={{ backgroundColor: '#818181', color: 'white' }} />;
  }
};