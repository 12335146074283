import React from 'react';
import {Typography, Grid} from '@mui/material';
import Prototypes from 'prop-types';

import DataNotFoundImage from '../assets/images/data_not_found.svg';
import { capitalizeFirstLetter } from '../js/lib/utils';

export default function DataNotFound({title}) {
  return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item><img src={DataNotFoundImage} alt="data-not-found" /></Grid>
        <Grid item sx={{pt: 2}}>
          <Typography variant='h6' color="secondary.contrastText.main">No {capitalizeFirstLetter(title)} Found</Typography>
        </Grid>
      </Grid>
  );
}

DataNotFound.prototypes = {
  title: Prototypes.string.isRequired,
};
