import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid2 as Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ExpenseForm from '../components/expense_form';
import { expensesApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, CoreButton } from '../../../core/index';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const { useSnackBarNotification, useSessionStoragePersist } = Hooks;
const { useCreateExpenseMutation } = expensesApi;

export default function CreateExpenseView() {
  const navigate = useNavigate();
  let initialData = sessionStorage.getItem(STORAGE_KEYS.EXPENSE);
  if (initialData) {
    initialData = JSON.parse(initialData);
  }
  const methods = useForm({
    defaultValues: initialData,
    mode: 'onChange',
  });

  const { handleSubmit, watch } = methods;

  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [addNewExpense, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useCreateExpenseMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Expense created successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
    return () => {
      if (isSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.EXPENSE);
      }
    }
  }, [isSuccess, isError, data, error]);

  useSessionStoragePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.EXPENSE });

  return (
    <Grid container>
      <Grid size={12}>
        <TuiAppBar title='New Expense' />
      </Grid>
      <Grid
        size={12}
        component='form'
        onSubmit={handleSubmit((userInput) => {
          userInput.dateTime = moment(userInput?.dateTime).valueOf();
          addNewExpense(userInput);
        })}
        sx={{ mt: 1 }}
        justifyContent='center'
        container
      >
        <FormProvider {...methods}>
          <Grid size={12} container sx={{ p: 1, height: 'inherit' }}>
            <ExpenseForm action="new"/>
          </Grid>
          <Grid
            size={12}
            container
            spacing={1}
            justifyContent='flex-end'
            alignContent='flex-end'
            alignItems='flex-end'
            sx={{
              height: '55vh',
              padding: 2,
            }}
          >
            <Grid>
              <CoreButton
                isLoading={isLoading}
                fullWidth={true}
                onClickHandler={() => navigate(-1)}
                variant="contained"
              >
                Back
              </CoreButton>
            </Grid>
            <Grid>
              <CoreButton
                isLoading={isLoading}
                type='submit'
                fullWidth={true}
                variant="contained"
              >
                Add
              </CoreButton>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </Grid>
  );
}
